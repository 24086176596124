<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Add Banner</h3>
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <div class="pl-lg-4 pt-4 pb-0">
                                    <div class="row">
                                        <div class="col-6 pr-lg-5 pb-0">
                                            <div class="form-group mb-0 pb-0">
                                                <lavel class="font"> Title</lavel>
                                                <input type="text " class="form-control mt-2">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 pr-5">
                                            <label for="exampleFormControlSelect1">Device</label>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Device</option>
                                                <option>Desktop</option>
                                                <option>Mobile</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-lg-4 pt-4">
                                    <div class="row">
                                        <div class="col-lg-6 pr-5">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Status</option>
                                                <option>enable</option>
                                                <option>disable</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-6 pr-5">
                                            <div><label>Image</label></div>
                                             <input type="file" class="upload-image" placeholder="Last name" accept="image/*" @change="uploadImage($event)" id="file-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-lg-4 pt-4">
                                    <div class="row">
                                        <div class="col-lg-6 pr-5">
                                             <label >Position</label>
                                            <input type="number" class="form-control" placeholder="posotion">
                                        </div>
                                        <div class="col-lg-6 pr-5">
                                            <div class="form-group mb-0 pb-0">
                                                <lavel class="font">URL</lavel>
                                                <input type="text " class="form-control mt-2">
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-lg-4 pt-4">
                                    <div class="row">
                                    <div class="col-6 lg-5 pr-5">
                                        <div class="form-group datetimepicker">
                                            <lavel class="font">From Date</lavel>
                                            <datepicker :typeable="true" input-class="form-control" v-model="fromDate" name="uniquename"></datepicker>
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">To Date</lavel>
                                            <datepicker :typeable="true" input-class="form-control" v-model="toDate" name="uniquename"></datepicker>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                 <div class="lg-4 pt-4">
                                    <div class="col-12 lg-5 pl-3 pr-3 mb-0">
                                        <div class="form-group col-12 mb-0">
                                            <lavel class="font">Content</lavel>
                                            <vue-editor v-model="content"></vue-editor>
                                        </div>
                                    </div>
                                 </div>
                                 <div class="pl-lg-4 pt-4">
                                    <div class="row">
                                        <div class="col-lg-6 pr-5 mb-2">
                                            <label for="exampleFormControlSelect1">Store</label>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Store</option>
                                                <option>enable</option>
                                                <option>disable</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                 <div class="pl-lg-4 pt-4 mb-4">
                                    <base-button type="primary" @click="add">Add</base-button>

                                 </div>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import Datepicker from 'vuejs-datepicker';
  export default {
components: { Datepicker ,VueEditor},
    name: 'user-profile',
    data() {
      return {
        model: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          zipCode: '',
          about: '',
        }
      }
    },
  };
</script>
<style scoped>
                                        
  .image-class{
      margin-top: 2.5rem !important;
  }
  .upload-image{
    border: 1px solid #cad1d7;
    background-color: #fff;
    border-radius :3px;
    padding :5px;
    height: 43px;
    width: 100%;
  };
  .date-picker div input{width:100% !important };
    
    
.font{ 
margin-bottom: 2;}
</style>
