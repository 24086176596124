<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
        </base-header>
            
        <div class="container-fluid mt--7">
            <div>
                <div class="settings col-xl-12 mr-5 width:80%">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class=" align-items-center">
                                <div class="col-8">
                                    <h2 class="mb-0">Settings</h2>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>
                        <template v-if="!isMobile">
                            <div slot="header" class="bg-white">

                            <div class="bg-white">
                                
                                <div class="row mb-6 pl-5 pr-5 mt-6">

                                    <div class="setting-tile col-4" style="display:inherit">
                                        <div class="setting-tile-bg">
                                            <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                            <div class="col-sm-8 pointer"  @click="open('/changePassword')">
                                                <a class="color">Password</a><br>
                                                <span>View and update your password</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="setting-tile col-4" style="display:inherit">
                                        <div class="setting-tile-bg">
                                            <div class="col1 dot"><i class="fas fa-map-marker-alt margin-setting"> </i></div>
                                            <div class="col-sm-8 pointer">
                                                <a class="color">Location</a><br>
                                                <span>View and update your store details</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="setting-tile col-4" style="display:inherit">
                                        <div class="setting-tile-bg">
                                            <div class="col1 dot"><i class="fas fa-money-bill-alt margin-setting"> </i></div>
                                            <!-- <div class="col-sm-1 m-0 col1">
                                                <i class="fas fa-money-bill-alt"> </i>
                                            </div> -->
                                            <div class="col-sm-8 pointer">
                                                <a class="color">Payment</a><br>
                                                <span>View and update your store details</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-6 pl-5 pr-5 mt">
                                    
                                    <div class="setting-tile col-4" style="display:inherit">
                                        <div class="setting-tile-bg">
                                            <div class="col1 dot"><i class="fas fa-user-circle margin-setting"> </i></div>
                                            <!-- <div class="col-sm-1 m-0 col1">
                                                <i class="fas fa-user-circle"> </i>
                                            </div> -->
                                            <div class="col-sm-8 pointer" @click="open('/plans')">
                                                <a class="color">Legal</a><br>
                                                <span>View and update your store details</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="setting-tile col-4" style="display:inherit">
                                        <div class="setting-tile-bg">
                                            <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                            <!-- <div class="col-sm-1 m-0 col1">
                                                <i class="fas fa-cog"> </i>
                                            </div> -->
                                            <div class="col-sm-8 pointer">
                                                <a class="color">Taxes</a><br>
                                                <span>View and update your store details</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="setting-tile col-4" style="display:inherit">
                                        <div class="setting-tile-bg">
                                            <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                            <div class="col-sm-8 pointer">
                                                <a class="color">Checkout</a><br>
                                                <span>View and update your store details</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </template>
                        <template v-else>
                            <div class="pl-lg">
                                <div class="main-div mb-3 mt-3">
                                    <div class="child-div">
                                        <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                        <div class="detail" @click="open">
                                            <a class="color">General</a><br>
                                            <span>View and update your store details</span>
                                        </div>
                                    </div>
                                    <div class="child-div"> 
                                        <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                        <div class="detail " @click="open">
                                            <a class="color">Location</a><br>
                                            <span>View and update your store details</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="main-div mb-3">
                                    <div class="child-div">
                                        <div class="col1 dot"><i class="fas fa-money-bill-alt margin-setting"> </i></div>
                                        <div class="detail" @click="open">
                                            <a class="color">Payment</a><br>
                                            <span>View and update your store details</span>
                                        </div>
                                    </div>
                                    <div class="child-div"> 
                                        <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                        <div class="detail " @click="open('/plans')">
                                            <a class="color">Plans and permission</a><br>
                                            <span>View and update your store details</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="main-div mb-3">
                                    <div class="child-div">
                                        <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                        <div class="detail" @click="open">
                                            <a class="color">Taxes</a><br>
                                            <span>View and update your store details</span>
                                        </div>
                                    </div>
                                    <div class="child-div"> 
                                        <div class="col1 dot"><i class="fas fa-cog margin-setting"> </i></div>
                                        <div class="detail " @click="open">
                                            <a class="color">Checkout</a><br>
                                            <span>View and update your store details</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- <template>
                            <transition name="modal" v-if="openModel">
                                <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="modal-container">
                                    <div class="modal-header">
                                     
                                    </div>
                                    <i class="fas fa-times-circle" style="float:right; font-sixe:23px"  @click="openModel=false"></i>
                                   <form @submit.prevent>
                                <h6 class="heading-small text-muted mb-4">User information</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Username"
                                                        placeholder="Username"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.username"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Email address"
                                                        placeholder="jesse@example.com"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.email"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="First name"
                                                        placeholder="First name"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.firstName"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Last name"
                                                        placeholder="Last name"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.lastName"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                              </form>
                                    </div>
                                </div>
                                </div>
                            </transition>
                        </template> -->
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { isMobile } from 'mobile-device-detect';

  export default {
    name: 'user-profile',
    data() {
      return {
        isMobile:false,
        model: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          zipCode: '',
          about: '',
        },
        openModel:false,
      }
    },
    mounted(){
      if(localStorage.getItem("role") == "vendor"){
        window.location.href = "/dashboard"
      }
      if(isMobile)this.isMobile = true;
    },
    methods:{
        open(type){
            console.log("type",type)
            this.$router.push(type)
            // alert("open")
            // this.openModel =  true
        }
    },
  };
</script>
<style scoped>
    .col1{
        /* max-width: 5.3%; */
        top:22px !important;
    }
    .card-body {
    background-color: white !important;
    }
    .setting-tile-bg {
        display: flex;
        padding: 1rem 0.5rem;
    }
    .setting-tile-bg:hover {
        background: rgba(0,0,0,0.05);
        border-radius: 0.5rem;
    }
    .setting-tile .dot {
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 50%;
        margin-left: 12px;
        text-align: center;
        margin-right: 18px;
        background: rgba(0,0,0,0.05);
        line-height: 3.6rem;
        font-size: 1.2rem;
    }
    .color{
        color:#2dce89
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        width: 70%;
        height: 50%;
        /* background-color: rgba(0, 0, 0, 0.5); */
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 800px;
        /* height: 60%; */
        margin: 0px auto;
        margin-top: 250px;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .margin-setting{
        margin-top: 6px;
    }
    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
         margin: 20px 0;
    }

.modal-default-button {
  float: right;
}


.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media only screen and (max-width: 767px) {
    .margin-setting{
        margin-top: 0px;
    }
    .dot {
     height: 20px;
    width: 19px;
    line-height: 24px;
    max-width: 40px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0;
    position: absolute;
    top: 20px !important;
        
    }
    .main-div{
        float: left;
        width: 100%;
        position: relative;
        
    }
    .child-div{
        bottom: 45px;
        /* position: absolute; */
        box-sizing: border-box;
        /* margin-left: 20px; */
        float: left;
        width:45%;
        padding-left: 12px;
    }
    .detail{
        font-size: small;
        float:left;
        padding-left: 33px;
    }
   
}
</style>

