<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <!-- <div class="card-header bg-transparent pb-5">
                        <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
                        <div class="btn-wrapper text-center">
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                                <span class="btn-inner--text">Github</span>
                            </a>
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                                <span class="btn-inner--text">Google</span>
                            </a>
                        </div>
                    </div> -->
                    <div class="card-body px-lg-5 py-lg-5">
                        <!-- <div class="text-center text-muted mb-4">
                            <small>Or sign in with credentials</small>
                        </div> -->
                        <form role="form">
                            <base-input class="input-group-alternative mb-2"
                                        placeholder="Email"
                                        addon-left-icon="ni ni-email-83"
                                        v-model="model.email"
                                        @input="checkInput">
                            </base-input><span style="color:red">{{emailError}}</span>

                            <base-input class="input-group-alternative mb-2"
                                        placeholder="Password"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="model.password"
                                        @keyup.enter="login"
                                        @input="checkInput">
                            </base-input><span style="color:red">{{passwordError}}</span>

                            <base-checkbox class="custom-control-alternative">
                                <span class="text-muted">Remember me</span>
                            </base-checkbox>
                            <div class="text-center">
                                <base-button type="primary" class="my-4" @click="login">Sign in</base-button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="row mt-3">
                    <div class="col-6">
                        <a href="#" class="text-light"><small>Forgot password?</small></a>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                    </div>
                </div> -->
            </div>
        </div>
</template>
<script>
import axios from "../axiosCustom";

  export default {
    name: 'login',
    data() {
      return {
        model: {
          email: '',
          password: ''
        },
        emailError : '',
        passwordError :  '',
      }
    },
    methods: {
        checkInput(){
            if(this.model.email) this.emailError = ''
            if(this.model.password) this.passwordError = ''
        },
        async login(){
            if(this.model.email && this.model.password){
            let payload ={
                email:this.model.email,
                password:this.model.password
            }
            let that = this
            try {
                let response = await axios.post('/vendor-login', payload, {withCredentials: true})

             if(response.message === 'logged in successfully'){
               localStorage.setItem("role", response.role);

                that.$root.accessToken = response.accessToken
                that.$cookies.set('accessToken', response.accessToken)
                that.$Msg.success('User Login Successfully', { position: 'top-right' ,timeout: 3000 });
               that.$router.push('/');
             }
            } 
            catch (error) {
                that.$Msg.error('Invalid Credentials', { position: 'top-right' ,timeout: 3000 });
            }
        }
        else{
            if(this.model.email) this.emailError =''
            else this.emailError = 'Email is required'
            
            if(this.model.password ) this.passwordError = ''
            else this.passwordError = 'Password is required'
        }
        }
    }
  }
</script>
<style>
.v-msg-box{
    width: 170px;
    border: none;
    border-radius: 10px;
    margin: 25px 10px;
    box-shadow: 0 10px 20px 0 rgba(0,255,0,0.1);
}
</style>
