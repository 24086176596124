<template>
 <div class="invoice-box mt-5">
  <table cellpadding="0" cellspacing="0">
    <tr class="top">
      <td colspan="8">
        <table>
          <tr class="borderT">
            <td class="title  pb-0">
             <img src="https://admin.wndw.in/images/alogo.jpg" style="width:100%; max-width:200px; background-color:white">
              <img src="https://wndw.in/images/fav2.ico" style="width:10%; float:right; max-width:50px; padding-top:20px; background-color:white">
            </td>
             
          </tr>
            <tr class="pb-0"  style="text-align:center">
             <td  class="col-12 pb-0"><h3 >Packing Slip</h3></td>
        </tr>
        </table>
      </td>
  
    </tr>
  
    <tr class="information">
      <td colspan="8">
        <table>
          <tr>
             <td >
               <tr >
                    <td class="pb-0 pt-0" style="text-align:left"><div style="width: 80px; float: left;"><b style="width:20px">Date: </b></div><div style="float:left"><span class="f12 pl-0">{{date}}</span></div></td>
                </tr>
                 <tr >
                    <td class="pb-0 pt-0" style="text-align:left">
                        <div style="width: 80px; float: left;"><b >Ship To: </b></div>
                        <div style="float:left">
                            <span class="f12">{{shippingAddress.full_name}}</span><br>
                            <span class="f12"> {{shippingAddress.street_address}} </span><br>
                            <span class="f12"> {{shippingAddress.city}},{{shippingAddress.state}} <br></span>
                        </div>
                    </td>
                </tr>
                 <tr >
                    <td class="pb-0 pt-0" style="text-align:left">
                         <div style="width: 80px; float: left;"><b >Email:</b></div>
                         <div style="float:left">
                         <span class="f12">{{customer.email}}</span>
                         </div></td>
                </tr>
            </td>
            <td >
               <tr >
                    <td class="pb-0 pt-0" style="text-align:left"><div style="width: 80px; float: left;">
                        <b style="width:20px">Tracking: </b></div><div style="float:left"><span class="f12">{{date}}</span></div></td>
                </tr>
                 <tr >
                    <td class="pb-0 pt-0" style="text-align:left">
                        <div style="width: 80px; float: left;"><b >Return Address: </b></div>
                        <div style="float:left">
                            <span class="f12">{{store.full_name}}</span><br>
                            <span class="f12"> {{store.address_primary}},{{store.address_secondary}} </span><br>
                            <span class="f12"> {{store.city}},{{store.state}}-{{store.pincode}}<br></span>
                        </div>
                    </td>
                </tr>
                 <tr >
                    <td class="pb-0 pt-0" style="text-align:left">
                         <div style="width: 80px; float: left;"><b >Order:</b></div>
                         <div style="float:left">
                         <span class="f12">{{order_no}}</span>
                         </div></td>
                </tr>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- <tr class="heading">
      <td colspan="3">Payment Method</td>
      <td>Check #</td>
    </tr> -->

    <!-- <tr class="details">
      <td colspan="9">Check</td>
      <td>1000</td>
    </tr> -->

    <tr class="heading slipBorder">
      <td class="text-left">Qty</td>
      <td class="text-left">SKU</td>
      <td class="text-left">Description</td>
    </tr>

    <tr class="item" v-for="item in printArray" :key="item.key">
      <td class="text-left f12">{{item.qty}}</td>
      <td class="text-left f12" >{{item.sku}}</td>
      <td class="text-left f12">{{ item.name }}</td> 
     
    </tr>

   
<!-- 
    <tr class="total">
      <td colspan="1"><b>TOTAL AMOUNT:</b><br>
      <span class="f10">All values are in INR</span></td>
      <td colspan="3"></td>
      <td>{{ total | currency }}</td>
    </tr> -->
    
    <!-- <tr class="borderT">
      <td colspan="1"><b>Total Amount in words:</b></td>
      <td colspan="3"></td><td>{{ total | currency }}</td>
    </tr> -->
    <!-- <tr class="borderT">
        <td colspan="2">
            Have a question? Our 9x7 customer service is
                hare to help you on +91 080 41281334
        </td>
        <td colspan="3">
            <b>Lighthouse Mall Management Company Pvt
            Limited Authorized Signature</b>
        </td>
    </tr> -->
    <tr ><td colspan="5" class="declaration">Declaration: <b class="f12">The goods sold as part of this shipment are intended for end user consumption and not for re-sale.</b></td></tr>
     <tr>
      <td colspan="5">
          <base-button type="primary" size="sm" style="float:right" @click="print">Print</base-button>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
 import axios from "../axiosCustom" ;
export default {
  components: { BaseButton },
    data (){
        return{
            customer:{},
            shippingAddress:{},
            date:'',
            order_no:'',
            printArray:[],
            store:{},
            
        }
  },
  computed: {
    total() {
      return this.items.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
    }
  },
  async mounted(){
      let d = new Date()
      this.date = `${d.getDate()}-${d.getMonth()}-${d.getFullYear()} `
      let ids  =  window.$cookies.get("ids")
      var idArr = JSON.parse(ids)
      
        var token = window.$cookies.get("accessToken")
        let params = {
                order_quote_id:this.$route.query.id
        }
        var res = await axios.post('/vendor/get-slip',params,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })

        this.printArray = [];

        idArr.forEach(e => {
            let index = res[0].accepted_orders.findIndex(el=>{
               return el.item_id==e
            })
            if(index>-1){
               this.printArray.push(res[0].accepted_orders[index])
            }
        });
        console.log("res", res[0])
        this.customer =  res[0].customer_details;
        this.shippingAddress =  res[0].shipping_address;
        this.order_no = res[0].order_increment_id;
        this.store = res[0].store;
  },
  methods: {
    async print() {
        console.log("window", window.document)
       const res=await window.print()
        if(res == undefined){
            this.$router.push({ name: 'Details'})

        }
       console.log("connn", res)
    //   this.items.push({ description: "", quantity: 1, price: 0 });
    }
  },
  filters: {
    currency(value) {
      return value.toFixed(2);
    }
  }

}
</script>

<style>
    .rowT{
        display: block;    line-height: 17px;
    }
    .slipBorder{
        border: 2px solid black !important;
    }
    .slipBorder td{border-right: 2px solid #000;}
    .item:last-child{border-bottom: 2px solid #000;}
</style>
    