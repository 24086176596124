<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-left">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid pl-0 align-items-left m-0">
        <div class="row">
          <div class="col-lg-10 ml-0 pl-0">
            <h3 class="text-white">
              <b>#{{ id }}</b>
             
            </h3>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Mark as Fulfilled</h3>
                </div>
              </div>
            </div>
            <template class="p-0">
              <div class="table-responsive">
                <table class="table tablesorter" >
                 
                  <thead >
                    
                    <tr >
                      <th v-for="item in actionHeaders" :key="item.id" >
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                 
                  <tbody >
                    <tr v-for="(item, i) in items" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td class="product-details">
                         
                        <span> {{item.name}}<br/>  <b> SKU: {{item.sku}}</b></span></td>
                      <td>{{ item.qty }}</td>
                      
                      <td >
                      <label class="form-checkbox">
                          <span @click="handleSelectedItems(item.item_id)" :id="'a_' + i" value="true" class="customCheckbox "  :class="{ 'checked':  selectedItems.includes(item.item_id) == true}"></span>
                        </label>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </template> </card
          ><br />
        </div>
      </div>
      <div class="col-lg-12 mr-3 pr-0 ml-0">
        <base-button
          type="primary"
          @click="fullfiled()"
          style="float: right;width:15%"
            v-if="selectedItems.length>0"
          >Confirm <i v-if="isConfirmLoader == true" class="fa fa-spinner fa-spin"> </i></base-button
        > 
        
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../axiosCustom';
export default {
  name: "fullfill",
  data() {
    return {     
    selectedItems:[],
    items:[],
    actionHeaders: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" }, 
        { name: "Fullfill",id:'fullfillment',align:'right' },
    ],
    id: "",
    isConfirmLoader:false
    };
  },

  async created() {
    this.id = this.$route.query.id
    this.fetchItems()      
  },
  async mounted() {
    
  },
  methods: {
    numberFormate(data){
      return new Intl.NumberFormat("en-IN").format(data)
    },
    async fetchItems(){
        var token = window.$cookies.get("accessToken");
        let params = {
            quote_id: this.id,
            
          };
          
        const res = await axios.post("/vendor/self-fulfilled", params, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        });
        this.items = res.data
    },
    handleSelectedItems(id){
        if(this.selectedItems.includes(id))
        {
            let filteredItems = this.selectedItems.filter(item=>item!=id)
            this.selectedItems = filteredItems
        }
        else{
            this.selectedItems.push(id)
        }
    },

    fullfiled(){
       
        this.isConfirmLoader =true;
        var token = window.$cookies.get("accessToken");
            let params = {
            quote_id: this.id,
            items_id:this.selectedItems
          };
        axios.post("/vendor/fulfilled", params, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        }).then(res=>{ 
            this.isConfirmLoader =false;
            this.$Msg.success(res.message, {
            position: "top-center",
            timeout: 3000,
        });
        this.$router.push({
        name: "Details",
        query: { id: this.id },
        // params: { item: item },
      });
        // this.fetchItems()
        }).catch(err=>{
            this.isConfirmLoader =false;
            this.$Msg.error(err.message, {
            position: "top-center",
            timeout: 3000,
        });
        })
          
    } 
  },
};

</script>

<style scoped>
.table thead th {
  border: 0;
}
.container-fluid.align-items-left {
  padding-left: 13px !important;
}
.customCheckbox{
  width: 20px;
  height: 20px;
  display: inline-block;
  border:1px solid #555;
  border-radius: 100%;
  margin-left: 15px;;
}
.customCheckbox::before {
    margin:4px;
    content: "\2713";
    color:#555; 
} 
.customUnCheckbox{
  width: 20px;
  height: 20px;
  display: inline-block;
  border:1px solid #555;
  border-radius: 100%;
  margin-left: 15px;;
}
.customUnCheckbox::before {
      margin:4px;
      content: "\2715";
      color:#555 !important; 
      font-size: 13px;
} 
/* .customCheckbox.checked{
  content: '&#10003';
  background-color: blue ;
} */


.checked {
  width: 20px;
  height: 20px;
  display: inline-block;
  border:1px solid #555;
  border-radius: 100%;
  margin-left: 15px;;
  background-color:#2dce89;
  border-color:#2dce89;
}
.checked::before {
    margin:4px;
    content: "\2713";
    color:#fff; 
} 
.unchecked::before {
    margin:4px;
    content: "\2715";
    color:#fff !important; 
    font-size: 13px;
  
} 
.unchecked {
  background-color:red;
  font-size: 13px;
  border-color:red;
  color: red;
  
}
</style>
    

