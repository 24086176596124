<template>
	<div>
		<base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-gradient-success opacity-8"></span>
			<!-- Header container -->
		</base-header>
		<div class="container-fluid mt--7 mb-4">
			<div class="row">
				<div class="col-xl-12 order-xl-1">
					<card shadow type>
						<div slot="header" class="bg-white border-0">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0">Intregate Theme</h3>
								</div>
								<div class="col-4 text-right"></div>
							</div>
						</div>
						<h6 class="heading-small text-muted mb-4 pl-4 pt-4">Select theme</h6>
						<div class="pl-lg-4 mb-4">
							<div class="container">
								<div class="row">
									<div class="col-lg-4" @click="selectTheme(1)">
										<img
											src="/img/fashion-thumb.jpg"
											class="img-rounded"
											:class="selectedTheme == 1 ? 'image-shade' : ''"
											alt="Cinque Terre"
											width="250"
											height
										/>
										<div class="form-check pt-2">
											<!-- <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    value = '1'
                                    v-model = selectedTheme
                                />
											<label class="form-check-label" for="flexRadioDefault1"> Theme 1 </label>-->
										</div>
									</div>
									<div class="col-lg-4" @click="selectTheme(2)">
										<img
											src="/img/groc-thumb.jpg"
											:class="selectedTheme == 2 ? 'image-shade' : ''"
											class="img-rounded"
											alt="Cinque Terre"
											width="250"
											height
										/>
										<div class="form-check pt-2"></div>
									</div>
									<div class="col-lg-4" @click="selectTheme(3)">
										<img
											src="/img/electronics-thumb.jpg"
											:class="selectedTheme == 3 ? 'image-shade' : ''"
											class="img-rounded"
											alt="Cinque Terre"
											width="250"
											height
										/>
										<div class="form-check pt-2"></div>
									</div>
								</div>
							</div>
						</div>
					</card>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-xl-12 order-xl-1">
					<card shadow type="secondary">
						<template>
							<form @submit.prevent>
								<!--  ----------------------menu section start -------------------- -->
								<div class="col-lg-4 pr-3 pl-0 mt-4">
									<h4 class="pl-3 mb-1">Select Outer Menu</h4>
								</div>

								<div class="pr-2">
									<div class="col-lg-12 pr-4 mr-1 pl-3 mb-4 mt-4">
										<multiselect
											v-model="outer_menu"
											:options="linkArray"
											:multiple="true"
											track-by="name"
											labal="name"
											:custom-label="customLabel"
											:close-on-select="false"
											openDirection="bottom"
											@select="onSelect($event)"
											@remove="onRemove($event)"
										>
											<span class="checkbox-label" slot="option" slot-scope="scope">
												<input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent />
												{{ scope.option.name }}
											</span>
										</multiselect>
									</div>
								</div>

								<div class="col-lg-4 pr-3 pl-0 mt-4">
									<h4 class="pl-3 mb-1">Select Inner Menu</h4>
								</div>

								<div class="pr-2">
									<div class="col-lg-12 pr-4 mr-1 pl-3 mb-4 mt-4">
										<multiselect
											v-model="inner_menu"
											:options="linkArray"
											:multiple="true"
											track-by="name"
											labal="name"
											:custom-label="customLabel"
											:close-on-select="false"
											openDirection="bottom"
											@select="onSelect($event)"
											@remove="onRemove($event)"
										>
											<span class="checkbox-label" slot="option" slot-scope="scope">
												<input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent />
												{{ scope.option.name }}
											</span>
										</multiselect>
									</div>
								</div>
								<!--  ----------------------menu section end -------------------- -->
								<!--  ----------------------logo/mobile section start -------------------- -->

								<div class="col-lg-4 pr-3 pl-0 mt-2">
									<h4 class="pl-3 mb-2">logo</h4>
								</div>
								<div class="col-md-4">
									<div class="row">
										<div class="col-md-6">
											<img :src="logo" width="150" height="100" />
										</div>
									</div>
									<div class="md-4 pt-4">
										<div class="row">
											<div class="col-md-11 pr-2 pb-3 float-left">
												<input
													type="file"
													class="upload-image"
													placeholder="Last name"
													accept="image/*"
													@change="uploadImage($event, 'logo')"
													id="file-input"
												/>
											</div>
										</div>
									</div>
								</div>

								<div class="row mt-4 ml-0 mr-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<!-- <input class="select-box" type="checkbox" value="" id="flexCheckChecked" v-model="slider_status" checked> -->
											<h4 class="mb-2 pl-4">Slider section</h4>
										</div>
									</div>
									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="2"
											v-model="slider_position"
											input-classes="form-control-alternative"
											readonly
										/>
									</div>

									<div class="col-md-8 pl-2 pr-0">
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="slider_width" />
											<label class="ml-3">Full width</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="slider_width" />
											<label class="ml-3">Boxed</label>
										</div>
									</div>
								</div>
								<div
									class="pl-4"
									style="position: relative"
									:class="this.slider_status == false ? 'hide-section' : ''"
								>
									<div class="row">
										<div class="col-md-4">
											<span style="font-weight: 600">Slide 1</span>
											<span
												class="fas fa-trash-alt delete-image"
                        @click.prevent="trash(`slider`, slider1)"
                        v-if="slider1 != 'undefined' && slider1 != ''"
											></span>
											<div class="row">
												<div class="col-md-6">
													<img :src="slider1" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'slider1')"
															id="file-input"
														/>
													</div>
												</div>
											</div>

											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select class="form-control" id="exampleFormControlSelect1" v-model="sliderOneLink">
													<option value>--Select link--</option>
													<option
														v-for="(item, i) in linkArray"
														:value="item"
														@click="selectLink(item)"
														:key="i"
													>{{ item.name }}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<span style="font-weight: 600">Slide 2</span>
											<span
												class="fas fa-trash-alt delete-image"
                        @click="trash(`slider`, slider2)"
												v-if="slider2 != 'undefined' && slider2 != ''"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="slider2" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'slider2')"
															id="file-input"
														/>
													</div>
												</div>
											</div>

											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select class="form-control" id="exampleFormControlSelect1" v-model="sliderTwoLink">
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>

										<div class="col-md-4">
											<span style="font-weight: 600">Slide 3</span>
											<span
												class="fas fa-trash-alt delete-image"
                        @click="trash(`slider`, slider3)"
												v-if="slider3 != 'undefined' && slider3 != ''"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="slider3" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'slider3')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select class="form-control" id="exampleFormControlSelect1" v-model="sliderThreeLink">
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<hr class="my-4" />

								<!--------------------------------   banner 1 start here ------------------------>
								<div class="row pl-0 ml-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<input
												class="select-box"
												type="checkbox"
												value
												id="flexCheckChecked"
												v-model="banner1_status"
												checked
											/>
											<div class="col-md-4 ml-3">
												<h4 class="mb-2 pl-3 mr-4">Banner section 1</h4>
											</div>
											<div class="col-md-6 ml-3">
												<span class="mb-2 ml-0 mr-4" style="font-weight: 600">Banner Title</span>
												<base-input
													alternative
													type="text"
													value="2"
													v-model="banner1_title"
													input-classes="form-control-alternative"
												/>
											</div>
										</div>
									</div>
									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="2"
											v-model="banner1_position"
											input-classes="form-control-alternative"
										/>
									</div>
									<div class="col-md-8 pl-2 pr-0">
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3">Layout</h6>
											<input
												class="pl-3 ml-3 mt-1"
												type="radio"
												@click="displayLayout(value)"
												value="1"
												v-model="banner1_arrangment"
											/>
											<label class="ml-3">1</label>
											<input
												class="pl-3 ml-3 mt-1"
												type="radio"
												@click="displayLayout(value)"
												value="2"
												v-model="banner1_arrangment"
											/>
											<label class="ml-3">1/2</label>
											<input
												class="pl-3 ml-3 mt-1"
												type="radio"
												@click="displayLayout(value)"
												value="3"
												v-model="banner1_arrangment"
											/>
											<label class="ml-3">1/3</label>
										</div>
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="banner1_width" />
											<label class="ml-3">Full width</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="banner1_width" />
											<label class="ml-3">Boxed</label>
										</div>
									</div>
								</div>
								<div
									class="pl-4"
									style="position: relative"
									:class="this.banner1_status == false ? 'hide-section' : ''"
								>
									<div class="row">
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 1</span>
											<span
                          @click="trash(`banner_one`, banner1_image1)"
												class="fas fa-trash-alt delete-image"
												v-if="
													banner1_image1 != 'undefined' && banner1_image1 != ''
												"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner1_image1" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner1_image1')"
															id="file-input"
														/>
													</div>
												</div>
											</div>

											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionOne_oneLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 2</span>
											<span
												class="fas fa-trash-alt delete-image"
                        @click="trash(`banner_one`, banner1_image2)"
                        v-if="
													banner1_image2 != 'undefined' && banner1_image2 != ''
												"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner1_image2" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner1_image2')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionOne_twoLink"
												>
													<option value>--Select link--</option>

													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>

										<div class="col-md-4">
											<span style="font-weight: 600">Banner 3</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner1_image3 != 'undefined' && banner1_image3 != ''
												"
                        @click="trash(`banner_one`, banner1_image3)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner1_image3" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner1_image3')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionOne_threeLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<!----------------------------------- banner 1 end here ------------------------------->
								<hr class="my-4" />

								<!-- ---------------------------------banner 2 start here ----------------------------->
								<div class="row pl-0 ml-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<input
												class="select-box"
												type="checkbox"
												value
												id="flexCheckChecked"
												v-model="banner2_status"
												checked
											/>
											<div class="col-md-4 ml-3">
												<h4 class="mb-2 pl-3">Banner section 2</h4>
											</div>
											<div class="col-md-6 ml-3">
												<span class="mb-2 ml-0 mr-4" style="font-weight: 600">Banner Title</span>
												<base-input
													alternative
													type="text"
													value="2"
													v-model="banner2_title"
													input-classes="form-control-alternative"
												/>
											</div>
										</div>
									</div>
									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="3"
											v-model="banner2_position"
											input-classes="form-control-alternative"
										/>
									</div>
									<div class="col-md-8 pl-2 pr-0">
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3">Layout</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="1" v-model="banner2_arrangment" />
											<label class="ml-3">1</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="2" v-model="banner2_arrangment" />
											<label class="ml-3">1/2</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="3" v-model="banner2_arrangment" />
											<label class="ml-3">1/3</label>
										</div>
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="banner2_width" />
											<label class="ml-3">Full width</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="banner2_width" />
											<label class="ml-3">Boxed</label>
										</div>
									</div>
								</div>
								<div
									class="pl-4"
									style="position: relative"
									:class="this.banner2_status == false ? 'hide-section' : ''"
								>
									<div class="row">
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 1</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner2_image1 != 'undefined' && banner2_image1 != ''
												"
                        @click.prevent="trash(`banner_two`, banner2_image1)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner2_image1" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner2_image1')"
															id="file-input"
														/>
													</div>
												</div>
											</div>

											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionTwo_oneLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 2</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner2_image2 != 'undefined' && banner2_image2 != ''
												"
                        @click="trash(`banner_two`, banner2_image2)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner2_image2" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner2_image2')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionTwo_twoLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>

										<div class="col-md-4">
											<span style="font-weight: 600">Banner 3</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner2_image3 != 'undefined' && banner2_image3 != ''
												"
                        @click="trash(`banner_two`, banner2_image3)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner2_image3" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner2_image3')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionTwo_threeLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<!-- ---------------------------------banner 2 end here ---------------------------------->

								<hr class="my-4" />

								<!-------------------------------------video start here----------------------------------->
								<div class="row pl-0 ml-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<input
												class="select-box"
												type="checkbox"
												value
												id="flexCheckChecked"
												v-model="video_status"
												checked
											/>
											<h6 class="heading-small text-muted mb-2 pl-3">Video Section</h6>
										</div>
									</div>

									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="4"
											v-model="video_position"
											input-classes="form-control-alternative"
										/>
									</div>
									<div class="col-md-8 pl-2 pr-0">
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="video_width" />
											<label class="ml-3">Full width</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="video_width" />
											<label class="ml-3">Boxed</label>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<span style="font-weight: 600">Video thumb</span>
									<div class="row">
										<div class="col-md-6">
											<img :src="video_thumb" width="150" height="100" />
										</div>
									</div>
									<div class="md-4 pt-4">
										<div class="row">
											<div class="col-md-11 pr-2 pb-3 float-left">
												<input
													type="file"
													class="upload-image"
													placeholder="Last name"
													accept="video/mp4, video/x-m4v, video/*"
													@change="uploadImage($event, 'video')"
													id="file-input"
												/>
											</div>
										</div>
									</div>
								</div>

								<div
									class="col-md-12 mb-4"
									style="position: relative"
									:class="this.video_status == false ? 'hide-section' : ''"
								>
									<base-input
										alternative
										label="Video"
										type="text"
										input-classes="form-control-alternative"
										v-model="video"
									/>
								</div>
								<!--------------------------------------- video end ----------------------------------------->

								<hr class="my-4" />

								<!-------------------------------------- banner 3 start here-------------------------------->

								<div class="row pl-0 ml-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<input
												class="select-box"
												type="checkbox"
												value
												id="flexCheckChecked"
												v-model="banner3_status"
												checked
											/>
											<div class="col-md-4 ml-3">
												<h4 class="mb-2 pl-3">Banner section 3</h4>
											</div>
											<div class="col-md-6 ml-3">
												<span class="mb-2 ml-0 mr-4" style="font-weight: 600">Banner Title</span>
												<base-input
													alternative
													type="text"
													value="2"
													v-model="banner3_title"
													input-classes="form-control-alternative"
												/>
											</div>
										</div>
									</div>

									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="5"
											v-model="banner3_position"
											input-classes="form-control-alternative"
										/>
									</div>
									<div class="col-md-8 pl-2 pr-0">
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3">Layout</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="1" v-model="banner3_arrangment" />
											<label class="ml-3">1</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="2" v-model="banner3_arrangment" />
											<label class="ml-3">1/2</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="3" v-model="banner3_arrangment" />
											<label class="ml-3">1/3</label>
										</div>
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="banner3_width" />
											<label class="ml-3">Full width</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="banner3_width" />
											<label class="ml-3">Boxed</label>
										</div>
									</div>
								</div>
								<div
									class="pl-4"
									style="position: relative"
									:class="this.banner3_status == false ? 'hide-section' : ''"
								>
									<div class="row">
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 1</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner3_image1 != 'undefined' && banner3_image1 != ''
												"
                        @click="trash(`banner_three`, banner3_image1)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner3_image1" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner3_image1')"
															id="file-input"
														/>
													</div>
												</div>
											</div>

											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionThree_oneLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 2</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner3_image2 != 'undefined' && banner3_image2 != ''
												"
                        @click="trash(`banner_three`, banner3_image2)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner3_image2" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner3_image2')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionThree_twoLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>

										<div class="col-md-4">
											<span style="font-weight: 600">Banner 3</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner3_image3 != 'undefined' && banner3_image3 != ''
												"
                        @click="trash(`banner_three`, banner3_image3)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner3_image3" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner3_image3')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionThree_threeLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>

								<!-------------------------------------- banner 3 end here ------------------------->

								<hr class="my-4" />

								<!----------------------------------------banner 4 start here----------------------->

								<div class="row pl-0 ml-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<input
												class="select-box"
												type="checkbox"
												value
												id="flexCheckChecked"
												v-model="banner4_status"
												checked
											/>
											<div class="col-md-4 ml-3">
												<h4 class="mb-2 pl-3">Banner section 4</h4>
											</div>
											<div class="col-md-6 ml-3">
												<span class="mb-2 ml-0 mr-4" style="font-weight: 600">Banner Title</span>
												<base-input
													alternative
													type="text"
													value="2"
													v-model="banner4_title"
													input-classes="form-control-alternative"
												/>
											</div>
										</div>
									</div>
									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="6"
											v-model="banner4_position"
											input-classes="form-control-alternative"
										/>
									</div>
									<div class="col-md-8 pl-2 pr-0">
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3">Layout</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="1" v-model="banner4_arrangment" />
											<label class="ml-3">1</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="2" v-model="banner4_arrangment" />
											<label class="ml-3">1/2</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="3" v-model="banner4_arrangment" />
											<label class="ml-3">1/3</label>
										</div>
										<div style="display: flex">
											<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
											<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="banner4_width" />
											<label class="ml-3">Full width</label>
											<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="banner4_width" />
											<label class="ml-3">Boxed</label>
										</div>
									</div>
								</div>
								<div
									class="pl-4"
									style="position: relative"
									:class="this.banner4_status == false ? 'hide-section' : ''"
								>
									<div class="row">
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 1</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner4_image1 != 'undefined' && banner4_image1 != ''
												"
                        @click="trash(`banner_four`, banner4_image1)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner4_image1" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner4_image1')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionFour_oneLink"
												>
													<option value>--Select link--</option>
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
										<div class="col-md-4">
											<span style="font-weight: 600">Banner 2</span>
											<span
												class="fas fa-trash-alt delete-image"
												v-if="
													banner4_image2 != 'undefined' && banner4_image2 != ''
												"
                        @click="trash(`banner_four`, banner4_image2)"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner4_image2" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner4_image2')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionFour_twoLink"
												>
													<option value>--Select link--</option>

													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>

										<div class="col-md-4">
											<span style="font-weight: 600">Banner 3</span>
											<span
												class="fas fa-trash-alt delete-image"
                        @click="trash(`banner_four`, banner4_image3)"
												v-if="
													banner4_image3 != 'undefined' && banner4_image3 != ''
												"
											></span>

											<div class="row">
												<div class="col-md-6">
													<img :src="banner4_image3" width="250" height="200" />
												</div>
											</div>
											<div class="md-4 pt-4">
												<div class="row">
													<div class="col-md-11 pr-2 pb-3 float-left">
														<input
															type="file"
															class="upload-image"
															placeholder="Last name"
															accept="image/*"
															@change="uploadImage($event, 'banner4_image3')"
															id="file-input"
														/>
													</div>
												</div>
											</div>
											<div class="col-md-11 pr-2 pl-0 pb-0 float-left">
												<select
													class="form-control"
													id="exampleFormControlSelect1"
													v-model="sectionFour_threeLink"
												>
													<option value>--Select link--</option>

													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<!---------------------------------------------- banner 4 end here -------------------------------->

								<hr class="my-4" />

								<!---------------------------------------------- category 1 --------------------------->
								<div class="row pl-0 ml-0">
									<div class="col-md-9 pl-0 pr-0">
										<div style="display: flex">
											<input
												class="select-box"
												type="checkbox"
												value
												id="flexCheckChecked"
												v-model="feature_cat1_status"
												checked
											/>
											<div class="col-md-4 ml-0 pl-0">
												<h4 class="mb-2 pl-3">Featured Category Section 1</h4>
											</div>
											<div class="col-md-6 ml-3">
												<span class="mb-2 ml-0 mr-4" style="font-weight: 600">Featured Category Title</span>

												<base-input
													alternative
													type="text"
													value="2"
													v-model="f1_title"
													input-classes="form-control-alternative"
												/>
											</div>
										</div>
									</div>
									<span class="mr-1 pl-3 ml-3">Position</span>
									<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
										<base-input
											alternative
											type="number"
											value="7"
											v-model="feature_cat1_position"
											input-classes="form-control-alternative"
										/>
									</div>
									<div style="display: flex">
										<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
										<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="f_c1_width" />
										<label class="ml-3">Full width</label>
										<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="f_c1_width" />
										<label class="ml-3">Boxed</label>
									</div>
								</div>
								<div
									style="position: relative"
									:class="
										this.feature_cat1_status == false ? 'hide-section' : ''
									"
								>
									<div class="row pl-0 ml-0">
										<div class="col-lg-4 pr-3 pl-3 mb-2">
											<label for="exampleFormControlSelect1">Featured Category 1</label>
											<select class="form-control" id="exampleFormControlSelect1" v-model="feature_cat_id1">
												<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
											</select>
										</div>
										<div class="col-lg-4 pr-3 pl-3 mb-2">
											<label for="exampleFormControlSelect1">Product per row</label>
											<select class="form-control" id="exampleFormControlSelect1" v-model="category1_product">
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5">5</option>
											</select>
										</div>
										<div class="col-lg-4 pr-5 mr-0 pl-4 mb-2">
											<label for="exampleFormControlSelect1">Number of rows</label>
											<select class="form-control" id="exampleFormControlSelect1" v-model="category1_row">
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5">5</option>
												<option value="6">6</option>
												<option value="7">7</option>
											</select>
										</div>
									</div>
								</div>

								<hr class="my-4" />
								<div class="mb-2">
									<!-- <div> -->
									<div class="row pl-0 ml-0">
										<div class="col-md-9 pl-0 pr-0">
											<div style="display: flex">
												<input
													class="select-box"
													type="checkbox"
													value
													id="flexCheckChecked"
													v-model="feature_cat2_status"
													checked
												/>
												<div class="col-md-4 ml-0 pl-0">
													<h4 class="mb-2 pl-3">Featured Category Section 2</h4>
												</div>
												<div class="col-md-6 ml-3">
													<span class="mb-2 ml-0 mr-4" style="font-weight: 600">Featured Category Title</span>
													<base-input
														alternative
														type="text"
														value="2"
														v-model="f2_title"
														input-classes="form-control-alternative"
													/>
												</div>
											</div>
										</div>
										<span class="mr-1 pl-3 ml-3">Position</span>
										<div class="col-md-1 pl-3 pr-0 ml-3 mb-0 position-width">
											<base-input
												alternative
												type="number"
												value="8"
												v-model="feature_cat2_position"
												input-classes="form-control-alternative"
											/>
										</div>
										<div class="col-md-8 pl-2 pr-0">
											<div style="display: flex">
												<h6 class="heading-small text-muted mb-2 pl-3 float-right">Display</h6>
												<input class="pl-3 ml-3 mt-1" type="radio" value="full" v-model="f_c2_width" />
												<span class="ml-3">Full width</span>
												<input class="pl-3 ml-3 mt-1" type="radio" value="boxed" v-model="f_c2_width" />
												<span class="ml-3">Boxed</span>
											</div>
										</div>
									</div>
									<div
										style="position: relative"
										:class="
											this.feature_cat2_status == false ? 'hide-section' : ''
										"
									>
										<div class="row pl-0 ml-0">
											<div class="col-lg-4 pr-3 pl-3 mb-2">
												<label for="exampleFormControlSelect1">Featured Category 2</label>
												<select class="form-control" id="exampleFormControlSelect1" v-model="feature_cat_id2">
													<option v-for="(item, i) in linkArray" :value="item" :key="i">{{ item.name }}</option>
												</select>
											</div>
											<div class="col-lg-4 pr-3 pl-3 mb-2">
												<label for="exampleFormControlSelect1">Product per row</label>
												<select class="form-control" id="exampleFormControlSelect1" v-model="category2_product">
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</div>
											<div class="col-lg-4 pr-5 mr-0 pl-4 mb-2">
												<label for="exampleFormControlSelect1">Number of rows</label>
												<select class="form-control" id="exampleFormControlSelect1" v-model="category2_row">
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div>
									<base-button
										class="mb-4 mr-4"
										type="primary"
										size="sm"
										style="float: right"
										@click="update()"
									>{{ isCreate ? "Create" : "Update" }}</base-button>
								</div>
							</form>
						</template>
					</card>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "../axiosCustom";
	import Multiselect from "vue-multiselect";
	export default {
		components: { Multiselect },
		name: "user-profile",
		data() {
			return {
				selectedTheme: 1,
				select: false,
				linkArray: [],
				isCreate: true,

				// slider1 :"https://www.w3schools.com/bootstrap/cinqueterre.jpg",
				// banner1_image1 : "https://www.w3schools.com/css/img_forest.jpg",
				// slider3 : "https://www.w3schools.com/css/img_mountains.jpg",
				// banner1:'https://image.shutterstock.com/image-photo/fresh-green-grass-panorama-on-600w-251742019.jpg',
				store_id: "",
				theme_id: "",
				options: [
					{ language: "JavaScript", library: "Vue.js", checked: false },
					{
						language: "JavaScript",
						library: "Vue-Multiselect",
						checked: false
					},
					{ language: "JavaScript", library: "Vuelidate", checked: false }
				],
				slider1: "",
				slider2: "",
				slider3: "",
				video_thumb: "",
				sliderOneLink: "",
				sliderTwoLink: "",
				sliderThreeLink: "",

				sectionOne_oneLink: "",
				sectionOne_twoLink: "",
				sectionOne_threeLink: "",

				sectionTwo_oneLink: "",
				sectionTwo_twoLink: "",
				sectionTwo_threeLink: "",
				sectionThree_oneLink: "",
				sectionThree_twoLink: "",
				sectionThree_threeLink: "",
				sectionFour_oneLink: "",
				sectionFour_twoLink: "",
				sectionFour_threeLink: "",
				slider_position: "1",
				banner1_position: "2",
				banner2_position: "3",
				video_position: "4",
				banner3_position: "5",
				banner4_position: "6",
				feature_cat1_position: "7",
				feature_cat2_position: "8",

				banner1_arrangment: 1,
				banner2_arrangment: 1,
				banner3_arrangment: 1,
				banner4_arrangment: 1,

				slider_width: "full",
				banner1_width: "full",
				banner2_width: "full",
				banner3_width: "full",
				banner4_width: "full",
				video_width: "full",
				f_c1_width: "full",
				f_c2_width: "full",

				slider_status: true,
				banner1_status: true,
				banner2_status: true,
				banner3_status: true,
				banner4_status: true,
				feature_cat1_status: true,
				feature_cat2_status: true,
				video_status: true,

				banner1_image1: "",
				banner1_image2: "",
				banner1_image3: "",
				banner2_image1: "",
				banner2_image2: "",
				banner2_image3: "",
				banner3_image1: "",
				banner3_image2: "",
				banner3_image3: "",
				banner4_image1: "",
				banner4_image2: "",
				banner4_image3: "",
				logo: "",
				logo_b: "",

				category1_product: "",
				category2_product: "",

				category1_row: "",
				category2_row: "",

				outer_menu: [],
				inner_menu: [],
				video: "",

				banner1_title: "",
				banner2_title: "",
				banner3_title: "",
				banner4_title: "",
				f2_title: "",
				f1_title: "",
				feature_cat_id1: "",
				feature_cat_id2: "",

				sliderOnePositiion: "",
				model: {
					username: "",
					email: "",
					firstName: "",
					lastName: "",
					address: "",
					city: "",
					country: "",
					zipCode: "",
					about: ""
				},
				user: {
					name: "",
					email: ""
				}
			};
		},
		async created() {
      if (localStorage.getItem("role") == "vendor") {
        window.location.href = "/dashboard";
      }
    },

		async mounted() {
        await this.$store.dispatch("getUser");
        await this.$store.dispatch("getCategory");
        this.linkArray = this.$store.state.category;
        this.getStoreFrontData();
        console.log("linkArray", this.linkArray.length);
        this.user = this.$store.state.user;
        this.linkArray.map(e => {
          e.checked = false;
        });
		},
		methods: {
      async trash(type, image_link){
        console.log("In trash");
        const token = window.$cookies.get("accessToken");
        let params = {
          store: this.user.store.id,
          type: type,
          image_link: image_link
        };
        const response = await axios.post(`/vendor/delete-section-value`,params,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if(response){
          window.location.reload();
        }
      },
			unsetSections() {
				this.slider_status = true;
				this.banner1_status = false;
				this.banner2_status = false;
				this.banner3_status = false;
				this.banner4_status = false;
				this.feature_cat1_status = false;
				this.feature_cat2_status = false;
				this.video_status = false;
			},
			customLabel(option) {
				return `${option.name}`;
			},
			onSelect(option) {
				let index = this.linkArray.findIndex(item => item.name == option.name);
				this.linkArray[index].checked = true;
			},

			onRemove(option) {
				let index = this.linkArray.findIndex(item => item.name == option.name);
				this.linkArray[index].checked = false;
			},

			async getStoreFrontData() {
				let token = window.$cookies.get("accessToken");
				let res = await axios({
					method: "post",
					url: `${axios.defaults.baseURL}/vendor/my-store`,
					headers: {
						Authorization: `Bearer ${token}`
					}
				});

				if (res.status) {
					this.isCreate = false;

					this.unsetSections();
					// console.log("res",res.data);
					this.selectedTheme = res.data.result.theme_id;
					this.logo = res.data.result.sections.store_logo[0].store_logo;
					this.logo_b = this.logo;
					this.outer_menu = res.data.result.sections.outer_menu?res.data.result.sections.outer_menu:[];
					this.inner_menu = res.data.result.sections.inner_menu?res.data.result.sections.inner_menu:[];
					console.log("key", Object.values(res.data.result.sections));
					for (const [key, value] of Object.entries(res.data.result.sections)) {
						console.log(key);
						console.log("value", value);
						switch (value.name) {
							case "video": {
								this.video_status = true;
								this.video_width = value.width;
								this.video_position = value.position && value.position.toString();
								this.video = value.video;
								this.video_thumb = value.link;
								this.video_b = this.video_thumb;
								break;
							}
							case "product_listing_one": {
								if (value.status == 1) {
									this.feature_cat1_status = true;
								} else {
									this.feature_cat1_status = false;
								}

								this.category1_product = value.product_listing_details
									? value.product_listing_details.no_of_products
									: 0;
								this.category1_row =
									value.product_listing_details &&
									value.product_listing_details.no_of_rows;
								this.f1_title = value.header_title;
								this.feature_cat1_position =
									value.position && value.position && value.position.toString();
								if (value.product_listing_details.category_id !== null) {
									let featureCat1 = this.linkArray.find(
										x =>
											x.id_category == value.product_listing_details.category_id
									);
									if (featureCat1) {
										this.feature_cat_id1 = featureCat1;
									} else {
										this.feature_cat_id1 = {
											id_category: parseInt(
												value.product_listing_details.category_id
											),
											name: value.name.trim(),
											url_key: value.url_key,
											checked: false
										};
									}
								}

								this.category1_product =
									value.product_listing_details.no_of_products;
								this.category1_row = value.product_listing_details.no_of_rows;
								this.f1_title = value.header_title;
								if (value.status == 1) {
									this.feature_cat1_status = true;
								} else {
									this.feature_cat1_status = false;
								}

								break;
							}
							case "product_listing_two": {
								this.feature_cat2_position = value.position && value.position.toString();
								if (value.product_listing_details.category_id !== null) {
									let feature_cat_id2 = this.linkArray.find(
										x =>
											x.id_category == value.product_listing_details.category_id
									);
									if (feature_cat_id2) {
										this.feature_cat_id2 = feature_cat_id2;
									} else {
										this.feature_cat_id2 = {
											id_category: parseInt(
												value.product_listing_details.category_id
											),
											name: value.name.trim(),
											url_key: value.url_key,
											checked: false
										};
									}
								}

								this.category2_product =
									value.product_listing_details.no_of_products;
								this.category2_row = value.product_listing_details.no_of_rows;
								this.f2_title = value.header_title;

								if (value.status == 1) {
									this.feature_cat2_status = true;
								} else {
									this.feature_cat2_status = false;
								}
								break;
							}
							case "slider": {
								this.slider1 = value.sliders[0].img_path;
								this.slider1_b = this.slider1;
								this.slider2 = value.sliders[1].img_path;
								this.slider2_b = this.slider2;

								this.slider3 = value.sliders[2].img_path;
								this.slider3_b = this.slider3;
								let sliderOneLink = this.linkArray.find(
									x => x.id_category == value.sliders[0].category_id
								);
								if (sliderOneLink) {
									this.sliderOneLink = sliderOneLink;
								} else {
									this.sliderOneLink = {
										id_category: parseInt(value.sliders[0].category_id),
										name:
											value.sliders[0].category_name &&
											value.sliders[0].category_name.trim(),
										url_key: value.sliders[0].img_link,
										checked: false
									};
								}
								let sliderTwoLink = this.linkArray.find(
									x => x.id_category == value.sliders[1].category_id
								);
								// this.sliderOneLink = this.updateSlides()
								if (sliderTwoLink) {
									this.sliderTwoLink = sliderTwoLink;
								} else {
									this.sliderTwoLink = {
										id_category: parseInt(value.sliders[1].category_id),
										name:
											value.sliders[1].category_name &&
											value.sliders[1].category_name.trim(),
										url_key: value.sliders[1].img_link,
										checked: false
									};
								}
								let sliderThreeLink = this.linkArray.find(
									x => x.id_category == value.sliders[2].category_id
								);
								if (sliderThreeLink) {
									this.sliderThreeLink = sliderThreeLink;
								} else {
									this.sliderThreeLink = {
										id_category: parseInt(value.sliders[2].category_id),
										name:
											value.sliders[2].category_name &&
											value.sliders[2].category_name.trim(),
										url_key: value.sliders[2].img_link,
										checked: false
									};
								}
								// this.linkArray.push(this.sliderThreeLink);

								this.slider_width = value.width;
								this.slider_position = value.position && value.position.toString();
								if (value.status == 1) {
									this.slider_status = true;
								} else {
									this.slider_status = false;
								}
								break;
							}
							case "banner_one": {
								if (value.status == 1) {
									this.banner1_status = true;
								} else {
									this.banner1_status = false;
								}
								this.banner1_position = value.position && value.position.toString();
								this.banner1_width = value.width;
								this.banner1_title = value.header_title;

								this.banner1_arrangment = value.arrangement;
								this.banner1_image1 = value.banners[0]
									? value.banners[0].img_path
									: null;
								this.banner1_image1_b = this.banner1_image1;
								this.banner1_image2 = value.banners[1]
									? value.banners[1].img_path
									: null;
								this.banner1_image2_b = this.banner1_image2;
								this.banner1_image3 = value.banners[2]
									? value.banners[2].img_path
									: null;
								this.banner1_image3_b = this.banner1_image3;
								let sectionOne_oneLink =
									value.banners[0] || value.banners[0].id_category
										? this.linkArray.find(
												x => x.id_category == value.banners[0].category_id )
										: null;

								if (sectionOne_oneLink) {
									this.sectionOne_oneLink = sectionOne_oneLink;
								} else {
									this.sectionOne_oneLink = {
										id_category: parseInt(value.banners[0].category_id),
										name: value.banners[0].category_name
											? value.banners[0].category_name.trim()
											: null,
										url_key: value.banners[0].img_link,
										checked: false
									};
								}
								// console.log("test",value.banners)
								let sectionOne_twoLink = value.banners[1]
									? this.linkArray.find(
											x => x.id_category == value.banners[1].category_id )
									: null;
								if (sectionOne_twoLink) {
									this.sectionOne_twoLink = sectionOne_twoLink;
								} else {
									this.sectionOne_twoLink = {
										id_category: parseInt(value.banners[1].category_id),
										name: value.banners[1].category_name ||value.banners[1]
											? value.banners[1].category_name
											: null,
										url_key: value.banners[1].img_link,
										checked: false
									};
								}
								let sectionOne_threeLink = value.banners[2]
									? this.linkArray.find(
											x => x.id_category == value.banners[2].category_id )
									: null;
								if (sectionOne_threeLink) {
									this.sectionOne_threeLink = sectionOne_threeLink;
								} else {
									this.sectionOne_threeLink = {
										id_category: value.banners[2]
											? parseInt(value.banners[2].category_id)
											: null,
										name: value.banners[2].category_name
											? value.banners[2].category_name.trim()
											: null,
										url_key: value.banners[2] ? value.banners[2].img_link : null,
										checked: false
									};
								}
								if (value.status == 1) {
									this.banner1_status = true;
								} else {
									this.banner1_status = false;
								}

								break;
							}
							case "banner_two": {
								console.log("called", value.status);
								if (value.status == 1){
									if (value.status == 1) {
									this.banner2_status = true;
								} else {
									this.banner2_status = false;
								}
								this.banner2_image1 = value.banners[0]
									? value.banners[0].img_path
									: null;
								this.banner2_image2 = value.banners[1]
									? value.banners[1].img_path
									: null;
								this.banner2_image3 = value.banners[2]
									? value.banners[2].img_path
									: null;
								this.banner2_image1_b = this.banner2_image1;
								this.banner2_image2_b = this.banner2_image2;
								this.banner2_image3_b = this.banner2_image3;
								let sectionTwo_oneLink = value.banners[0]
									? this.linkArray.find(
											x => x.id_category == value.banners[0].category_id )
									: null;
								if (sectionTwo_oneLink) {
									this.sectionTwo_oneLink = sectionTwo_oneLink;
								} else {
									this.sectionTwo_oneLink = {
										id_category: value.banners[0]
											? parseInt(value.banners[0].category_id)
											: null,
										name: value.banners[0].category_name
											? value.banners[0].category_name.trim()
											: null,
										url_key: value.banners[0] ? value.banners[0].img_link : null,
										checked: false
									};
								}
								let sectionTwo_twoLink = value.banners[1]
									? this.linkArray.find(
											x => x.id_category == value.banners[1].category_id )
									: null;
								if (sectionTwo_twoLink) {
									this.sectionTwo_twoLink = sectionTwo_twoLink;
								} else {
									this.sectionTwo_twoLink = {
										id_category: value.banners[1]
											? parseInt(value.banners[1].category_id)
											: null,
										name: value.banners[1].category_name
											? value.banners[1].category_name
											: null,
										url_key: value.banners[1] ? value.banners[1].img_link : null,
										checked: false
									};
								}
								let sectionTwo_threeLink = value.banners[2]
									? this.linkArray.find(
											x => x.id_category == value.banners[2].category_id  )
									: null;
								if (sectionTwo_threeLink) {
									this.sectionTwo_threeLink = sectionTwo_threeLink;
								} else {
									this.sectionTwo_threeLink = {
										id_category: value.banners[2]
											? parseInt(value.banners[2].category_id)
											: null,
										name: value.banners[2].category_name
											? value.banners[2].category_name.trim()
											: null,
										url_key: value.banners[2] ? value.banners[2].img_link : null,
										checked: false
									};
								}
								this.banner2_position = value.position && value.position.toString();
								this.banner2_width = value.width;

								this.banner2_arrangment = value.arrangement;
								this.banner2_title = value.header_title;

								}
								
								break;
							}
							case "banner_three": {
								if (value.status == 1) {
									this.banner3_status = true;
								} else {
									this.banner3_status = false;
								}
								this.banner3_image1 = value.banners[0].img_path;
								this.banner3_image2 = value.banners[1].img_path;
								this.banner3_image3 = value.banners[2].img_path;
								this.banner3_image1_b = this.banner3_image1;
								this.banner3_image2_b = this.banner3_image2;
								this.banner3_image3_b = this.banner3_image3;
								this.banner3_title = value.header_title;

								let sectionThree_oneLink = this.linkArray.find(
									x => x.id_category == value.banners[0].category_id
								);
								if (sectionThree_oneLink) {
									this.sectionThree_oneLink = sectionThree_oneLink;
								} else {
									this.sectionThree_oneLink = {
										id_category: value.banners[0]
											? parseInt(value.banners[0].category_id)
											: null,
										name: value.banners[0].category_name
											? value.banners[0].category_name.trim()
											: null,
										url_key: value.banners[0].img_link,
										checked: false
									};
								}
								let sectionThree_twoLink = this.linkArray.find(
									x => x.id_category == value.banners[1].category_id
								);
								if (sectionThree_twoLink) {
									this.sectionThree_twoLink = sectionThree_twoLink;
								} else {
									this.sectionThree_twoLink = {
										id_category: value.banners[1].category_id
											? parseInt(value.banners[1].category_id)
											: null,
										name:value.banners[1]|| value.banners[1].category_name
											? value.banners[1].category_name
											: null,
										url_key: value.banners[1].img_link,
										checked: false
									};
								}
								let sectionThree_threeLink = this.linkArray.find(
									x => x.id_category == value.banners[2].category_id
								);
								if (sectionThree_threeLink) {
									this.sectionThree_threeLink = sectionThree_threeLink;
								} else {
									this.sectionThree_threeLink = {
										id_category: parseInt(value.banners[2].category_id),
										name: value.banners[2].category_name
											? value.banners[2].category_name.trim()
											: null,
										url_key: value.banners[2].img_link,
										checked: false
									};
								}
								this.banner3_position = value.position && value.position.toString();
								this.banner3_width = value.width;
								if (value.status == 1) {
									this.banner3_status = true;
								} else {
									this.banner3_status = false;
								}
								this.banner3_arrangment = value.arrangement;
								break;
							}
							case "banner_four": {
								if (value.status == 1) {
									if (value.status == 1) {
										this.banner4_status = true;
									} else {
										this.banner4_status = false;
									}
									this.banner4_title = value.header_title;
									this.banner4_image1 = value.banners[0].img_path;
									this.banner4_image2 = value.banners[1].img_path;
									this.banner4_image3 = value.banners[2].img_path;
									this.banner4_image1_b = this.banner4_image1;
									this.banner4_image2_b = this.banner4_image2;
									this.banner4_image3_b = this.banner4_image3;
									let sectionFour_oneLink = this.linkArray.find(
										x => x.id_category == value.banners[0].category_id
									);
									if (sectionFour_oneLink) {
										this.sectionFour_oneLink = sectionFour_oneLink;
									} else {
										this.sectionFour_oneLink = {
											id_category: parseInt(value.banners[0].category_id),
											name: value.banners[0].category_name.trim(),
											url_key: value.banners[0].img_link,
											checked: false
										};
									}
									let sectionFour_twoLink = this.linkArray.find(
										x => x.id_category == value.banners[1].category_id
									);
									if (sectionFour_twoLink) {
										this.sectionFour_twoLink = sectionFour_twoLink;
									} else {
										this.sectionFour_twoLink = {
											id_category: parseInt(value.banners[1].category_id),
											name: value.banners[1].category_name ?value.banners[1].category_name:null,
											url_key: value.banners[1].img_link,
											checked: false
										};
									}
									let sectionFour_threeLink = this.linkArray.find(
										x => x.id_category == value.banners[2].category_id
									);
									if (sectionFour_threeLink) {
										this.sectionFour_threeLink = sectionFour_threeLink;
									} else {
										this.sectionFour_threeLink = {
											id_category: parseInt(value.banners[2].category_id),
											name: value.banners[2].category_name 
												? value.banners[2].category_name.trim()
												: null,
											url_key: value.banners[2].img_link,
											checked: false
										};
									}
								}

								if (value.banners[2] != null) {
									this.banner4_image3 = value.banners[2].img_path;
									this.banner4_image3_b = this.banner4_image3;
									let sectionFour_threeLink = this.linkArray.find(
										x => x.id_category == value.banners[2].category_id
									);
									if (sectionFour_threeLink) {
										this.sectionFour_threeLink = sectionFour_threeLink;
									} else {
										this.sectionFour_threeLink = {
											id_category: parseInt(value.banners[2].category_id),
											name:
												value.banners[2] || value.banners[2].category_name.trim(),
											url_key: value.banners[2].img_link,
											checked: false
										};
									}
								}
								this.banner4_position = value.position && value.position.toString();
								this.banner4_width = value.width;
								this.banner4_title = value.header_title;

								if (value.status == 1) {
									this.banner4_status = true;
								} else {
									this.banner4_status = false;
								}
								this.banner4_arrangment = value.arrangement;
								break
							}
						}

						
					}
				}
				// else {

				// }
			},

			selectTheme(theme) {
				this.selectedTheme = theme;
				this.select = true;
			},
			selectLink(item) {
				this.sliderOneLink = item;
			},

			async update() {
				var menuArr = [];
				var InnerMenuArr = [];

				if (this.selectedTheme == "") {
					alert("please select theme");
					return;
				}
				if (this.outer_menu.length == 0) {
					alert("Please select outer menu");
					return;
				} else {
					this.outer_menu.map(e => {
						menuArr.push(e.id_category);
					});
				}
				if (this.inner_menu.length) {
					this.inner_menu.map(e => {
						InnerMenuArr.push(e.id_category);
					});
				}
				// let array = [this.slider_position,this.banner1_position,this.banner2_position,this.banner3_position
				//             ,this.banner4_position,this.feature_cat1_position,this.feature_cat2_position,]

				// const distinct = [...new Set(array.map(x=>x))]
				// console.log("distinct",distinct)
				// if(distinct.length != array.length){
				//     alert("Please check position")
				//     return
				// }

				var bodyFormData = new FormData();
				let token = window.$cookies.get("accessToken");

				bodyFormData.append("slider[]", []);
				if (this.slider_status == false) {
					bodyFormData.append("slider_link", {});
					bodyFormData.append("slider_width", "");
					bodyFormData.append("slider_position", "");
					bodyFormData.append("slider_status", 0);
				} else {
					if (
						this.slider1_b == undefined &&
						this.slider2_b == undefined &&
						this.slider3_b == undefined
					) {
						alert("Please select banner image");
						return;
					}

					if (
						this.slider1_b !== undefined &&
						this.sliderOneLink.id_category == undefined
					) {
						alert("Please select link for slider 1");
						return;
					}
					if (
						this.slider2_b !== undefined &&
						this.sliderTwoLink.id_category == undefined
					) {
						alert("Please select link for slider 2");
						return;
					}
					if (
						this.slider3_b !== undefined &&
						this.sliderThreeLink.id_category == undefined
					) {
						alert("Please select link for slider 3");
						return;
					}
					bodyFormData.append(
						"slider_link",
						JSON.stringify({
							image_one: {
								category_id: this.sliderOneLink.id_category,
								url_key: this.sliderOneLink.url_key,
								name: this.sliderOneLink.category_name
							},
							image_two: {
								category_id: this.sliderTwoLink.id_category,
								url_key: this.sliderTwoLink.url_key,
								name: this.sliderTwoLink.category_name
							},
							image_three: {
								category_id: this.sliderThreeLink.id_category,
								url_key: this.sliderThreeLink.url_key,
								name: this.sliderThreeLink.category_name
							}
						})
					);
					bodyFormData.append("slider_image_one", this.slider1_b);
					bodyFormData.append("slider_image_two", this.slider2_b);
					bodyFormData.append("slider_image_three", this.slider3_b);
					bodyFormData.append("slider_width", this.slider_width);
					bodyFormData.append("slider_position", this.slider_position);
					bodyFormData.append("slider_status", 1);
				}

				bodyFormData.append("banner1[]", []);
				if (this.banner1_status == false) {
					bodyFormData.append("banner1_link", {});
					bodyFormData.append("banner1_status", 0);
					bodyFormData.append("banner1_width", "");
					bodyFormData.append("banner1_arrangment", "");
					bodyFormData.append("banner1_position", "");
					this.banner1_position = "";
				} else {
					if (
						this.banner1_image1_b == undefined &&
						this.banner1_image2_b == undefined &&
						this.banner1_image3_b == undefined
					) {
						alert("Please select banner 1 image");
						return;
					}
					// if(this.banner1_image1_b !== undefined && this.sectionOne_oneLink.id_category == undefined){
					//     alert("Please select link for Banner 1")
					//     return
					// }
					// if(this.banner1_image2_b !== undefined && this.sectionOne_twoLink.id_category == undefined){
					//     alert("Please select link for Banner 2")
					//     return
					// }
					// if(this.banner1_image3_b !== undefined && this.sectionOne_threeLink.id_category == undefined){
					//     alert("Please select link for Banner 3")
					//     return
					// }
					bodyFormData.append("banner_one_image_one", this.banner1_image1_b);
					bodyFormData.append("banner_one_image_two", this.banner1_image2_b);
					bodyFormData.append("banner_one_image_three", this.banner1_image3_b);
					bodyFormData.append("banner1_status", 1);
					bodyFormData.append(
						"banner1_link",
						JSON.stringify({
							image_one: {
								category_id: this.sectionOne_oneLink.id_category,
								url_key: this.sectionOne_oneLink.url_key,
								name: this.sectionOne_oneLink.category_name
							},
							image_two: {
								category_id: this.sectionOne_twoLink.id_category,
								url_key: this.sectionOne_twoLink.url_key,
								name: this.sectionOne_twoLink.category_name
							},
							image_three: {
								category_id: this.sectionOne_threeLink.id_category,
								url_key: this.sectionOne_threeLink.url_key,
								name: this.sectionOne_threeLink.category_name
							}
						})
					);

					bodyFormData.append("banner1_width", this.banner1_width);
					bodyFormData.append("banner1_arrangment", this.banner1_arrangment);
					bodyFormData.append("banner1_position", this.banner1_position);
					bodyFormData.append("banner1_title", this.banner1_title);
				}
				bodyFormData.append("banner2[]", []);
				if (this.banner2_status == false) {
					bodyFormData.append("banner2_width", "");
					bodyFormData.append("banner2_status", 0);
					bodyFormData.append("banner2_arrangment", "");
					bodyFormData.append("banner2_link", {});
					bodyFormData.append("banner2_position", "");
					this.banner2_position = "";
				} else {
					if (
						this.banner2_image1_b == undefined &&
						this.banner2_image1 == undefined &&
						this.banner2_image2_b == undefined &&
						this.banner2_image2 == undefined &&
						this.banner2_image3_b == undefined &&
						this.banner2_image3 == undefined
					) {
						alert("Please select banner 2 image");
						return;
					}
					// if(this.banner2_image1_b !== undefined && this.sectionTwo_oneLink.id_category == undefined){
					//     alert("Please select link for banner 2")
					//     return
					// }
					// if(this.banner2_image2_b !== undefined && this.sectionTwo_twoLink.id_category == undefined){
					//     alert("Please select link for banner 2")
					//     return
					// }
					// if(this.banner2_image3_b !== undefined && this.sectionTwo_threeLink.id_category == undefined){
					//     alert("Please select link for banner 2")
					//     return
					// }
					bodyFormData.append("banner2_width", this.banner2_width);
					bodyFormData.append("banner2_status", 1);
					bodyFormData.append("banner2_arrangment", this.banner2_arrangment);
					bodyFormData.append(
						"banner2_link",
						JSON.stringify({
							image_one: {
								category_id: this.sectionTwo_oneLink.id_category,
								url_key: this.sectionTwo_oneLink.url_key,
								name: this.sectionTwo_oneLink.category_name
							},
							image_two: {
								category_id: this.sectionTwo_twoLink.id_category,
								url_key: this.sectionTwo_twoLink.url_key,
								name: this.sectionTwo_twoLink.category_name
							},
							image_three: {
								category_id: this.sectionTwo_threeLink.id_category,
								url_key: this.sectionTwo_threeLink.url_key,
								name: this.sectionTwo_threeLink.category_name
							}
						})
					);
					bodyFormData.append("banner2_position", this.banner2_position);
					bodyFormData.append("banner_two_image_one", this.banner2_image1_b);
					bodyFormData.append("banner_two_image_two", this.banner2_image2_b);
					bodyFormData.append("banner_two_image_three", this.banner2_image3_b);
					bodyFormData.append("banner2_title", this.banner2_title);
				}
				bodyFormData.append("banner3[]", []);
				if (this.banner3_status == false) {
					bodyFormData.append("banner3_link", {});
					bodyFormData.append("banner3_status", 0);
					bodyFormData.append("banner3_width", "");
					bodyFormData.append("banner3_position", "");
					bodyFormData.append("banner3_arrangment", "");
					this.banner3_position = "";
				} else {
					if (
						this.banner3_image1_b == undefined &&
						this.banner3_image2_b == undefined &&
						this.banner3_image3_b == undefined
					) {
						alert("Please select banner 3 image");
						return;
					}
					// if(this.banner3_image1_b !== undefined && this.sectionThree_oneLink.id_category == undefined){
					//     alert("Please select link for banner 3")
					//     return
					// }
					// if(this.banner3_image2_b !== undefined && this.sectionThree_twoLink.id_category == undefined){
					//     alert("Please select link for banner 3")
					//     return
					// }
					// if(this.banner3_image3_b !== undefined && this.sectionThree_threeLink.id_category == undefined){
					//     alert("Please select link for banner 3")
					//     return
					// }
					bodyFormData.append("banner_three_image_one", this.banner3_image1_b);
					bodyFormData.append("banner_three_image_two", this.banner3_image2_b);
					bodyFormData.append("banner_three_image_three", this.banner3_image3_b);
					bodyFormData.append(
						"banner3_link",
						JSON.stringify({
							image_one: {
								category_id: this.sectionThree_oneLink.id_category,
								url_key: this.sectionThree_oneLink.url_key,
								name: this.sectionThree_oneLink.category_name
							},
							image_two: {
								category_id: this.sectionThree_twoLink.id_category,
								url_key: this.sectionThree_twoLink.url_key,
								name: this.sectionThree_twoLink.category_name
							},
							image_three: {
								category_id: this.sectionThree_threeLink.id_category,
								url_key: this.sectionThree_threeLink.url_key,
								name: this.sectionThree_threeLink.category_name
							}
						})
					);
					bodyFormData.append("banner3_status", 1);
					bodyFormData.append("banner3_width", this.banner3_width);
					bodyFormData.append("banner3_position", this.banner3_position);
					bodyFormData.append("banner3_arrangment", this.banner3_arrangment);
					bodyFormData.append("banner3_title", this.banner3_title);
				}

				bodyFormData.append("banner4[]", []);
				if (this.banner4_status == false) {
					bodyFormData.append("banner4_status", 0);
					bodyFormData.append("banner4_link", {});
					bodyFormData.append("banner4_width", "");
					bodyFormData.append("banner4_arrangment", "");
					bodyFormData.append("banner4_position", "");
					this.banner4_position = "";
				} else {
					if (
						this.banner4_image1_b == undefined &&
						this.banner4_image2_b == undefined &&
						this.banner4_image3_b == undefined
					) {
						alert("Please select banner 4 image");
						return;
					}
					// if(this.banner4_image1_b !== undefined && this.sectionFour_oneLink.id_category == undefined){
					//     alert("Please select link for banner 4")
					//     return
					// }
					//  if(this.banner4_image2_b !== undefined && this.sectionFour_twoLink.id_category == undefined){
					//     alert("Please select link for banner 4")
					//     return
					// } if(this.banner4_image3_b !== undefined && this.sectionFour_threeLink.id_category == undefined){
					//     alert("Please select link for banner 4")
					//     return
					// }
					bodyFormData.append("banner_four_image_one", this.banner4_image1_b);
					bodyFormData.append("banner_four_image_two", this.banner4_image2_b);
					bodyFormData.append("banner_four_image_three", this.banner4_image3_b);
					bodyFormData.append("banner4_status", 1);
					bodyFormData.append(
						"banner4_link",
						JSON.stringify({
							image_one: {
								category_id: this.sectionFour_oneLink.id_category,
								url_key: this.sectionFour_oneLink.url_key,
								name: this.sectionFour_oneLink.category_name
							},
							image_two: {
								category_id: this.sectionFour_twoLink.id_category,
								url_key: this.sectionFour_twoLink.url_key,
								name: this.sectionFour_twoLink.category_name
							},
							image_three: {
								category_id: this.sectionFour_threeLink.id_category,
								url_key: this.sectionFour_threeLink.url_key,
								name: this.sectionFour_threeLink.category_name
							}
						})
					);
					bodyFormData.append("banner4_width", this.banner4_width);
					bodyFormData.append("banner4_arrangment", this.banner4_arrangment);
					bodyFormData.append("banner4_position", this.banner4_position);
					bodyFormData.append("banner4_title", this.banner4_title);
				}
				if (this.feature_cat1_status == false) {
					bodyFormData.append("feature_cat_id1_position", "");
					bodyFormData.append("feature_cat_id1_status", 0);
					bodyFormData.append("feature_cat_id1", "");
					bodyFormData.append("feature_cat_id1_no_of_products", 0);
					bodyFormData.append("feature_cat_id1_no_of_rows", 0);
					this.feature_cat1_position = "";
				} else {
					bodyFormData.append(
						"feature_cat_id1_position",
						this.feature_cat1_position
					);
					bodyFormData.append("feature_cat_id1_status", 1);
					bodyFormData.append(
						"feature_cat_id1",
						`${this.feature_cat_id1.id_category}`
					);
					bodyFormData.append(
						"feature_cat_id1_no_of_products",
						this.category1_product
					);
					bodyFormData.append("feature_cat_id1_no_of_rows", this.category1_row);
					console.log("this.f2_titlethis.f2_title", this.f1_title);
					if (this.f1_title == "") {
						alert("Plese write title for feature category");
						return;
					}
					bodyFormData.append("feature_cat_id1_title", this.f1_title);
				}

				if (this.feature_cat2_status == false) {
					bodyFormData.append("feature_cat_id2", "");
					bodyFormData.append("feature_cat_id2_status", 0);
					bodyFormData.append("feature_cat_id2_no_of_products", 0);
					bodyFormData.append("feature_cat_id2_no_of_rows", 0);
					bodyFormData.append("feature_cat_id2_position", "");

					this.feature_cat2_position = "";
				} else {
					bodyFormData.append(
						"feature_cat_id2",
						`${this.feature_cat_id2.id_category}`
					);
					bodyFormData.append("feature_cat_id2_status", 1);
					bodyFormData.append(
						"feature_cat_id2_no_of_products",
						this.category2_product
					);
					bodyFormData.append("feature_cat_id2_no_of_rows", this.category2_row);
					bodyFormData.append(
						"feature_cat_id2_position",
						this.feature_cat2_position
					);
					if (this.f2_title == "") {
						alert("Plese write title for feature category");
						return;
					}
					bodyFormData.append("feature_cat_id2_title", this.f2_title);
				}
				if (this.video_status == false) {
					bodyFormData.append("video", "");
					bodyFormData.append("video_status", 0);
					bodyFormData.append("video_width", "");
					bodyFormData.append("video_position", "");
					bodyFormData.append("video_thumb", "");
					this.video_position = "";
				} else {
					bodyFormData.append("video", this.video);
					bodyFormData.append("video_thumb", this.video_b);
					bodyFormData.append("video_status", 1);
					bodyFormData.append("video_width", this.video_width);
					bodyFormData.append("video_position", this.video_position);
				}
				bodyFormData.append("store_id", this.user.store.id);
				bodyFormData.append("theme_id", this.selectedTheme);
				bodyFormData.append("menu", menuArr);
				bodyFormData.append("side_menu", InnerMenuArr);
				bodyFormData.append("store_logo", this.logo_b);

				// findout same position //

				let positionArr = [
					this.slider_position,
					this.banner1_position,
					this.banner2_position,
					this.banner3_position,
					this.banner4_position,
					this.video_position,
					this.feature_cat1_position,
					this.feature_cat2_position
				];
				const result = positionArr.filter(arr => arr !== "");
				if (this.hasDuplicates(result)) {
					alert("Please check given position");
					return;
				} else {
					console.log("No Duplicates found.");
				}
				this.url = this.isCreate ? "/vendor/theme-setup" : "/vendor/update-store";
				var that = this;
				let res = await axios({
					method: "post",
					url: this.url,
					data: bodyFormData,
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				if (res.message == "Theme Setup Done") {
					that.$Msg.success("Theme saved successfully", {
						position: "top-center",
						timeout: 3000
					});
					this.$router.push("/about");
				} else {
					this.$Msg.error("Something went wrong", {
						position: "top-center",
						timeout: 3000
					});
				}
				// .then(function (response) {
				//     console.log("then")
				//     console.log("this", that)
				//     that.$Msg.sucess("Theme saved successfully", {
				//         position: "top-center",
				//         timeout: 3000,
				//     });
				//     console.log(response);
				// })
				// .catch(function (response) {
				//     console.log("catch")

				//     that.$Msg.error("Something went wrong", {
				//         position: "top-center",
				//         timeout: 3000,
				//     });
				//     console.log(response);
				// });
			},

			hasDuplicates(arr) {
				return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
			},

			async uploadImage(e, where) {
				var image_b = e.target.files[0];
				const image = e.target.files[0];
				const reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = e => {
					if (where == "logo") {
						this.logo = e.target.result;
						this.logo_b = image_b;
					}

					if (where == "slider1") {
						this.slider1 = e.target.result;
						this.slider1_b = image_b;
					}
					if (where == "slider2") {
						this.slider2 = e.target.result;
						this.slider2_b = image_b;
					}
					if (where == "slider3") {
						this.slider3 = e.target.result;
						this.slider3_b = image_b;
					}
					if (where == "banner1_image1") {
						this.banner1_image1 = e.target.result;
						this.banner1_image1_b = image_b;
					}
					if (where == "banner1_image2") {
						this.banner1_image2 = e.target.result;
						this.banner1_image2_b = image_b;
					}
					if (where == "banner1_image3") {
						this.banner1_image3 = e.target.result;
						this.banner1_image3_b = image_b;
					}
					if (where == "banner2_image1") {
						this.banner2_image1 = e.target.result;
						this.banner2_image1_b = image_b;
					}
					if (where == "video") {
						this.video_thumb = e.target.result;
						this.video_b = image_b;
					}
					if (where == "banner2_image2") {
						this.banner2_image2 = e.target.result;
						this.banner2_image2_b = image_b;
					}
					if (where == "banner2_image3") {
						this.banner2_image3 = e.target.result;
						this.banner2_image3_b = image_b;
					}
					if (where == "banner3_image1") {
						this.banner3_image1 = e.target.result;
						this.banner3_image1_b = image_b;
					}
					if (where == "banner3_image2") {
						this.banner3_image2 = e.target.result;
						this.banner3_image2_b = image_b;
					}
					if (where == "banner3_image3") {
						this.banner3_image3 = e.target.result;
						this.banner3_image3_b = image_b;
					}
					if (where == "banner4_image1") {
						this.banner4_image1 = e.target.result;
						this.banner4_image1_b = image_b;
					}
					if (where == "banner4_image2") {
						this.banner4_image2 = e.target.result;
						this.banner4_image2_b = image_b;
					}
					if (where == "banner4_image3") {
						this.banner4_image3 = e.target.result;
						this.banner4_image3_b = image_b;
					}
				};
			}
		}
	};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
	.image-class {
		margin-top: 2.5rem !important;
	}
	.upload-image {
		border: 1px solid #cad1d7;
		background-color: #fff;
		border-radius: 3px;
		padding: 5px;
		height: 43px;
		width: 94%;
	}
	.input#file-input:focus {
		border: 1px solid #cad1d7 !important;
	}
	.small-width {
		width: 80%;
	}
	.delete-image {
		font-weight: 600;
		float: right;
		margin-right: 39px;
		cursor: pointer;
	}
</style>
