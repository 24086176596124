<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <!-- <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Performance"
                                type="gradient-info"
                                sub-title="49,65%"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0">

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
            </div> -->
        </base-header>
       
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                       Discount
                                    </h3>
                                    </div>
                                    <div class="col text-right">
                                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                                    </div>
                                </div>
                            </div>
                            
                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th>
                                            <label class="form-checkbox m-0">
                                                <input type="checkbox" v-model="selectAll" @click="select">
                                                <i class="form-icon"></i>
                                            </label>
                                        </th>
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>
                                    <tbody :class="tbodyClasses">
                                        <tr v-for="item in data" :key="item.id">
                                        <td>
                                            <label class="form-checkbox">
                                                <input type="checkbox" :value="item.id_product" v-model="selected">
                                                <i class="form-icon"></i>
                                            </label>
                                        </td>
                                        <td>{{item.order_quote_id}}</td>
                                        <td v-if="item.order_items.length > 0">{{item.order_items[0].created_at}}</td>

                                        <td v-if="item.customer !== null">{{item.customer.full_name}}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{item.order_items.length}}</td>
                                        <td></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
<!--   
                            <div class="card-footer d-flex justify-content-end"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                            <base-pagination total=120></base-pagination>
                            </div> -->

                          </div>
                    <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
                </div>
            </div>
            <!-- <div class="row mt-5">
                <div class="col">
                    <projects-table type="dark" title="Dark Table"></projects-table>
                </div>
            </div> -->
        </div>

    </div>
</template>
<script>
  export default {
    name: 'tables',
    components: {
      
    },
    data () {
        return {
            data:[],
            selected: [],
            selectAll: false,
            headers : [
             { name: 'Order',value:'discount'},
             { name: 'Date',value:'discount'},
             { name:'Customer',value:'store'},
             { name:'Total',value:'status'},
             { name:'Payment',value:'status'},
             { name:'Items',value:'status'},
             { name:'Delivery Method',value:'status'},

            ]
        }
    },
      props: {
   
        theadClasses: {
        type: String,
        default: '',
        description: '<thead> css classes'
        },
        tbodyClasses: {
        type: String,
        default: '',
        description: '<tbody> css classes'
        }
    },
    computed: {
        tableClass() {
        return this.type && `table-${this.type}`;
        },
    },
    
    async mounted(){
        await this.$store.dispatch('getOrders',{item: this.page, key:'all'})
        console.log("this.",this.$store.state.orders)
        // this.data= this.$store.state.orders
    },
    
     methods: {
     select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.data) {
            this.selected.push(this.data[i].id_product);
          }
        }
      },
     }

  };
</script>
<style></style>
