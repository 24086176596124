<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                    >
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
        </base-header>

        <div class="container-fluid mt-3">
            <div class="row" style="float: left">
                 <div class="col-md-5" >
                   <div class="">
                       <h3>Static Pages</h3>
                        you can create your own legal pages, or create them from templates and customize them. The templates aren't legal advice and need to be customized for your store.
                        To keep your store secure, it's not possible to add audio, videos, or media widgets.
                        By using these templates you agree that you've read and agreed to the
                    </div> 
                </div>
                <div class="col-md-7 order-xl-1 mb-2" >
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0"><h2>About Us</h2>
                        <span >
                            When you save your policies, they are linked in the footer of your checkout pages. You may also need to add your policies to menus in your online store.
                        </span>
                             <div class="row align-items-center mt-2 mb-2">
                                
                                <div class="col-12 " style="max-height:500px;overflow-x: auto">
                                    <vue-editor v-model="aboutContent"></vue-editor>
                                </div>
                                
                            </div>
                        </div>
                        <div slot="footer">
                            <base-button size="sm" type="primary" @click="updateAbout">Update</base-button>
                        </div>
                    </card>
                    <card shadow type="secondary" class="mt-4">
                        <div slot="header" class="bg-white border-0"><h2>Return</h2>
                         <span >
                            When you save your policies, they are linked in the footer of your checkout pages. You may also need to add your policies to menus in your online store.
                        </span>
                        <div class="row align-items-center mt-2 mb-2">
                            <br/>
                            <div class="col-12 ">
                                <vue-editor v-model="returnContent"></vue-editor>
                            </div>
                        </div>
                        </div>
                        <div slot="footer">
                            <base-button size="sm" type="primary" @click="updateRefund">Update</base-button>
                        </div>
                    </card>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0"><h2>Shipping</h2>
                        <span >
                            When you save your policies, they are linked in the footer of your checkout pages. You may also need to add your policies to menus in your online store.
                        </span>
                             <div class="row align-items-center mt-2 mb-2">
                                
                                <div class="col-12 " style="max-height:500px;overflow-x: auto">
                                    <vue-editor v-model="shippingContent"></vue-editor>
                                </div>
                                
                            </div>
                        </div>
                        <div slot="footer">
                            <base-button size="sm" type="primary" @click="updateShipping">Update</base-button>
                        </div>
                    </card>
                    <card shadow type="secondary" class="mt-4">
                        <div slot="header" class="bg-white border-0"><h2>Contact US</h2>
                         <span >
                             When you save your policies, they are linked in the footer of your checkout pages. You may also need to add your policies to menus in your online store
                        </span>
                            <div class="row align-items-center mt-2 mb-2">
                                <br/>
                                <div class="col-12 ">
                                    <vue-editor v-model="policiesContent"></vue-editor>
                                </div>
                            </div>
                        </div>
                        <div slot="footer">
                            <base-button size="sm" type="primary" @click="updatePolicies">Update</base-button>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script> 
import axios from "../axiosCustom" ;
import { VueEditor } from "vue2-editor";
export default {
    components: {
      VueEditor
    },                            
    data(){
        return{
        aboutContent:null,
        returnContent:null,
        shippingContent:null,
        policiesContent:null,
        }
    },
  created() {
    if (localStorage.getItem("role") == "vendor") {
      window.location.href = "/dashboard";
    }
  },
  async mounted(){
        let token = window.$cookies.get("accessToken")
        var result = await axios.get('/vendor/pages',{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        let aboutIndex = result.findIndex(e=>{ if(e.url_key == "about-us")
          return e
        })
        let shippingtIndex = result.findIndex(e=>{ if(e.url_key == "shipping")
          return e
        })
        let refundIndex = result.findIndex(e=>{ if(e.url_key == "return")
          return e
        })
        let policyIndex = result.findIndex(e=>{ if(e.url_key == "contact-us")
          return e
        })
        let data =  result[aboutIndex].content.replace('↵','');
        let formattedDataAbout= data.replace(/(?:\r\n|\r|\n)/g, '')
        this.aboutContent = formattedDataAbout;

        let dataR = result[refundIndex].content.replace('↵','')
        let dataformattedDataReturn = dataR.replace(/(?:\r\n|\r|\n)/g, '')
        this.returnContent = dataformattedDataReturn

        let dataS = result[shippingtIndex].content.replace('↵','')
        let dataformattedDataShipping = dataS.replace(/(?:\r\n|\r|\n)/g, '')
        this.shippingContent = dataformattedDataShipping

        let dataP = result[policyIndex].content.replace('↵','')
        let dataformattedDataPolicy = dataP.replace(/(?:\r\n|\r|\n)/g, '')
        this.policiesContent = dataformattedDataPolicy
    },
    methods:{
        async updateRefund(){
            console.log("this.conte", this.returnContent);
            let params={
                content: this.returnContent,
                url_key : 'return',
                name: 'Return'
            }
             let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/update-page',params,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
           
        },

         async updateAbout(){
            console.log("this.conte", this.content);
            let params={
                content: this.aboutContent,
                url_key : 'about-us',
                name: 'About Us'
            }
            let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/update-page',params,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
           
        },
        async updateShipping(){
            let params={
                content: this.shippingContent,
                url_key : 'shipping',
                name: 'Shipping'

            }
            let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/update-page',params,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
            
        },
        async updatePolicies(){
            let params={
                content: this.policiesContent,
                name:'Contact Us',
                // include_top_menu:'yes',
                // include_footer_menu:'yes',
                // status:'active',
                // meta_title:"FAQ - LHM",
                // meta_keywords:"FAQ - LHM",
                // meta_description:"FAQ - LHM",
                // position:6,
                url_key : 'contact-us'
            }
            let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/update-page',params,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
            
        },

    },
}
</script>

<style>

</style>