<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

      <div class="container-fluid mt--7">
        <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                       Media
                                    </h3>
                                    </div>
                                    <div class="col text-right">
                                    <!-- <base-button type="primary" @click="add" size="sm">Upload Banner</base-button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>
                                    <tbody :class="tbodyClasses">
                                        <tr >
                                          <td>Image</td>
                                          <td><img :src="image" class="image-class pr-0"></td>
                                          <td>
                                            <a  class="pointer" style="display:inline-block" target="_blank" :href=image><input  v-model="image"></a></td>
                                          <td>
                                          <base-button type="primary" size="sm" @change="uploadImage($event)">Upload</base-button>

                                          </td>
                                        </tr>
                                        <tr >
                                          <td>Banner</td>
                                          <td><img :src="bannerImage" class="image-class pr-0"></td>
                                          <td>
                                            <a  class="pointer" style="display:inline-block" target="_blank" :href=bannerImage><input v-model="bannerImage"></a></td>
                                          <td>
                                            <base-button type="primary"  size="sm" @change="uploadImage($event)">Upload</base-button>
                                          </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
        </div>
                <!-- <div class="col-xl-12 order-xl-1 card-body-scoped">
                      <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Upload Media</h3>
                                </div>
                            </div>
                        </div>
                        <div class="p-5">
                          <div class="row">
                            <label class="mr-2 mt-4"><b>Store Image</b></label>
                            <div class="col-lg-2 pt-2  " style="display:flex; height:50px">
                                <img :src="image" class="image-class pr-0">
                            </div>
                            <input class = "mt-4 pl-2" type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
                          </div>
                          <br/>
                          <div class="row">
                            <label class="mr-2 mt-4"><b>Store Banner</b></label>
                            <div class="col-lg-2 pt-2 pr-0 " style="display:flex; height:50px">
                                <img :src="bannerImage" class="image-class">
                            </div>
                            <input class = "mt-4 pl-0" type="file" accept="image/*" @change="uploadImage($event,'banner')" id="file-input">
                          </div>
                          <base-button size="sm" style="float: right;" class=" margin mb-3"  type="primary">Upload</base-button>
                        </div>
                    </card>
          </div> -->
      </div>
  </div>
</template>

<script>
import axios from '../axiosCustom'
export default {
  components: {  },
  data(){
    return{
      image:'',
       headers : [
             { name: 'Type'},
             { name: 'File'},
             { name: 'URL',},
            { name:'',},
            ],
      bannerImage:'',
    }
  },
  created() {
    if (localStorage.getItem("role") == "vendor") {
      window.location.href = "/dashboard";
    }
  },
  async mounted(){
      await this.$store.dispatch('getUser')
      this.store = this.$store.state.user.store;
      this.image =  this.store.image;
      this.bannerImage =  this.store.store_banner;
    },
  methods:{
      async uploadImage(e,type){
        if(type == 'banner'){
          this.bannerImage = e.target.files[0];
          this.bImage = e.target.files[0];

          const reader = new FileReader();
          reader.readAsDataURL(this.bannerImage);
          reader.onload = e =>{
          this.bannerImage = e.target.result;
          };
          this.upload('banner')

        }
        else{
          this.image = e.target.files[0];
          this.store_image = e.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(this.image);
          reader.onload = e =>{
          this.image = e.target.result;
          };
          this.upload('image')
        }
          
        },
        async upload(type){
          var bodyFormData = new FormData();
          let token = window.$cookies.get("accessToken")
          if(type=='banner'){
            this.url = '/vendor/update-store-banner'
            bodyFormData.append('image' , this.bImage);
          }
          else{
            this.url = '/vendor/update-store-image'
            bodyFormData.append('image' , this.store_image);

          }

          axios({
              method: 'post',
              url: this.url,
              data: bodyFormData,
              headers: {
          'Authorization': `Bearer ${token}`
          }
          })
            .then(function (response) {
                //handle success
                console.log(response);
            })
            .catch(function (response) {
              this.$Msg.error(response, {
            position: "top-center",
            timeout: 2000,
          });
                //handle error
                console.log(response);
            });
        }
  }
}
</script>


<style scoped>
.image-class{
  height: 50px;
    width: 100px;
}
</style>