<template>
  <div>
      <div class="newmodal" v-if="showMessage">
			<div class="newmodal-dialog" style="width:40%">
				<div class="modal-content" style="left:0">
          <div class="modal-header" style="padding:1rem">
            <h2>Personilization Message</h2>
              <button v-if="hideclosepopbtn" type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showMessage=false">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          
					<div class="modal-body" style="padding:1rem">
            <p>{{ordermessage}}</p>
					</div>
				</div>
			</div>
		</div>

   <div class="newmodal" v-if="priceChange">
			<div class="newmodal-dialog">
				<div class="modal-content" style="left:0">
          <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLabel">Update price</h2>
              <button v-if="hideclosepopbtn" type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeupdatepricemodal">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div style="color:red" class="pricechangeerr">{{pricechangeError}}</div>
					<div style="height:500px;overflow-y:scroll" class="modal-body">
            <div class="table-responsive">
           
                  <table class="table">
                    <thead>
                    <tr>
                     
                      <th>check</th>
                      <th scope="col">Product</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                      <th scope="col">Change Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(product,index) in this.checkitemselected" :key="index">
                     <td v-if="product.selected==true">
                      <label class="form-checkbox">
                          <span @click="changeproducts(index)" :id="'add_'+index" class="customCheckbox"></span>
                      </label>
                        </td>
                     
                      <td>{{product.name}}</td>
                      <td>{{product.qty}}</td>
                      <td>{{product.price}}</td>
                      <td>{{product.row_total}}</td>
                      <td><input type="number" v-model="checkitemselected[index].selling_price"></td>
                      
                    </tr>
                    </tbody>
                  </table>
                  <base-button
                  type="primary"
                  @click="acceptupdatedorder()"
                  style="float: right;margin-right:5px"
                  >Confirm and Accept<i v-if="isConfirmLoader == true" class="fa fa-spinner fa-spin"> </i></base-button> 

                </div>
					</div>
				</div>
			</div>
		</div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-left">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid pl-0 align-items-left m-0">
        <div class="row">
          <div class="col-lg-10 ml-0 pl-0">
            <h3 class="text-white">
              <b>#{{ this.$store.state.details.order_increment_id }}</b>
            </h3>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Mark as Accepted</h3>
                </div>
              </div>
            </div>

            <template class="p-0">
              <div class="table-responsive">
                <table class="table tablesorter" :class="tableClass">
                 
                  <thead :class="theadClasses">
                    <tr  v-if="hopedOrder == true || grocery == true">
                      <th v-for="item in actionHeadersHoped" :key="item.id" >
                        {{ item.name }}
                      </th>
                    </tr>
                    <tr  v-if="hopedOrder == false && grocery == false">
                      <th v-for="item in actionHeaders" :key="item.id" >
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    <tr v-for="(item, i) in data.order_items" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td class="product-details"><img :src="`https://images.wndw.in/live/inside/100x100/`+item.image.image" width="45px" height="auto" style="float:left">
                        <span> {{item.name}}<br/>  <b> SKU: {{item.sku}}</b></span></td>
                      <td>{{ item.qty }}</td>
                      <td>₹ {{ numberFormate(item.selling_price) }}</td>
                      <td>₹ {{ numberFormate(item.qty * (item.row_total)) }}</td>
                      <td>  <span class="personilizebtn" v-if="item.personalization_message!=null" @click="showPersonilization(item.personalization_message)">Yes</span>  <span v-else>No</span>  </td>
                      <td v-if="hopedOrder == false && grocery == false">
                      <label class="form-checkbox">
                          <span @click="customChecked('accept',i)" :id="'a_' + i" value="true" class="customCheckbox "  :class="{ 'checked':  item.selected == true}"></span>
                        </label>
                      </td>
                      <td v-if="hopedOrder == false && grocery == false">
                        <label class="form-checkbox">
                          <span class="customUnCheckbox" @click="customChecked('reject',i)" :id="'r_' + i" value="false"  :class="{ 'unchecked': item.selected == false && ind == i}" ></span>
                        </label>'
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template> 
            </card>
          <br />
        </div>
      </div>
      <!-- Prescription Code Starts -->
      <div class="prescription" v-if="data.prescription.length>0">
        
        <div><span><strong>Customer Name :</strong> {{data.prescription[0].customer_name}}  </span> | <span><strong>Customer Number :</strong> {{data.prescription[0].phone}} </span>  </div> <br>
            <div v-for="(item,index) in data.prescription"  :key="index">
            <div v-if="item.callback=='true'" style="display:flex;flex-direction:column">
              <span style="color:black"><strong>Prescription : Not Provided</strong>  </span>
              <span style="color:black"><strong>Customer has requested a callback : {{item.phone}}</strong>   </span>

             </div> 
             <br>
            <button v-if="item.callback=='false'" class="prescrip"><a style="color:white" :href="data.prescription[index].image_url"  target="_blank">Prescription{{index+1}}</a><br/></button>
            </div>
      </div>
        
    

      <div v-if="isPrescription == true">
                <div class="modal" style="display: block; width: 100%">
                  <div class="modal-dialog">
                    <div class="modal-content t-300" style="width: 790px">
                      <div class="modal-header">
                       <h4>Prescription</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          @click="closePrescription"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <template>
                          <table class="table tablesorter" :class="tableClass">
                            <thead :class="theadClasses">
                            </thead>
                            <tbody :class="tbodyClasses">
                              <tr>
                                <td ><img class="prespic" :src="prescriptionData.image_url" ></td>
                              </tr>
                                <tr>
                                  <td><button>
                     <a :href="prescriptionData.image_url"  target="_blank">View Full Image</a> </button>
                      
                      </td>
                                </tr>
                            </tbody>
                          </table>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      <!-- Prescription Code Ends -->
      <!-- My Code -->
      <div v-if="isModal" id="model">
        <div class="modal" style="display:block; width:100%">
          <div class="modal-dialog" style="width:100%">
            <div class="modal-content t-100" style="max-height:600px;overflow-y:auto; width:150%">
              <div class="modal-header">
                  <h3 class="modal-title pointer">View Product</h3>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col">Store Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Shipping</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="data.store_id" v-for="data in responseData">
                      <th>{{data.store_name}}</th>
                      <th>Rs. {{data.price}}</th>
                      <th>Rs. {{data.discount}}</th>
                      <th>Rs. {{data.shipping}}</th>
                      <th>Rs. {{data.amount}}</th>
                      <td>
                        <button type="button" @click="acceptHoppedOrder(data.store_id)" class="btn btn-success"><i class="fa fa-check"></i></button>
                        <button type="button" @click="rejectHoppedOrder(data.store_id)" class="btn btn-danger"><i class="fa fa-times"></i></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="confirmDialog">
        <div class="modal" style="display:flex; align-items: center; width:100%">
          <div class="modal-dialog" style="width:100%; display: flex">
            <div class="modal-content t-100" style="display: flex; padding: 5px; overflow: hidden; max-height:200px; width:100%">
              <div class="modal-header">
                <h3 class="modal-title pointer">Success</h3>
              </div>
              <div class="modal-body">
                <p>The Action is Completed. Click close to reload the page</p>
              </div>
              <button @click="reloadPage" type="button" class="btn btn-primary">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isVendor" class="col-lg-12 mr-3 pr-0 ml-0">
        <base-button
            type="primary"
            @click="openModal()"
            style="float: right;width:25%"
            v-if="hopedOrder"
        >Hopped Stores</base-button>
      </div>
      <div class="col-lg-12 mr-3 pr-0 ml-0">
        
        <base-button
          type="primary"
          @click="acceptData()"
          style="float: right;width:15%"
            v-if="hopedOrder == false && grocery == false"
          >Confirm <i v-if="isConfirmLoader == true" class="fa fa-spinner fa-spin"> </i></base-button> 
        <base-button
          type="primary"
          @click="changePrice()"
          style="float: right;margin-right:5px"
          :disabled='updatepricebtndisbale'
            v-if="check==true && this.$store.state.updateval.allow_price_subtraction==1 || this.$store.state.updateval.allow_price_addition==1"
          >Update price</base-button
        > 
        <base-button v-if="((hopedOrder || grocery) && !isVendor) && data.order_items.length > 0"
          type="primary"
          @click="acceptOrderAll('accepted')"
          style="float: right;width:15%"
          
          > All Accept <i v-if="isConfirmLoader == true" class="fa fa-spinner fa-spin"> </i></base-button
        >
        <base-button v-if="(hopedOrder || grocery) && data.order_items.length > 0 && !isVendor"
          type="primary"
          @click="acceptOrderAll('rejected')"
          style="float: right; margin-right:6px;width:15%; background-color:red; border:red; height:44px"
          >All Reject <i v-if="isConfirmLoader == true" class="fa fa-spinner fa-spin"> </i> </base-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../axiosCustom';
export default {
  name: "user-profile",
  data() {
    return {
      ordermessage:'',
      showMessage:false,
      updatepricebtndisbale:false,
      hideclosepopbtn:true,
      priceChangeProducts:[],
      pricechangeError:'',
      checkitemselected:[],
      check:true,
      priceChange:false,
      prescriptionData:[],
      action: "",
      isPrescription :false,
      confirmDialog: false,
      hopped: false,
      isVendor: false,
      isModal: false,
      responseData: [],
      isConfirmLoader: false,
      editable: false,
      //  selected:[],
      ind:'',
      data:{
        order_items:[
        ]
      },
      selected: true,
      acceptOrder:false,
      hopedOrder:false,
      grocery: false,
      rejectOrder:false,
      model: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        country: "",
        zipCode: "",
        about: "",
      },
      headers: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        { name: "Price" },
        { name: "Amount" },
      ],
      actionHeadersHoped:[
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        { name: "Price" },
        { name: "Total" },
      ],
      actionHeaders: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        { name: "Price" },
        { name: "Total" },
        {name:"Personalization Message"},
        { name: "Accept",id:'accept',align:'right' },
        { name: "Reject" , id: 'reject'},

      ],
      id: "",
    };
  },
  props: {
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`  ;
    },
  },
  async created() {
    if(localStorage.getItem("role") == "vendor"){
      this.isVendor = true;
    }
  },
  async mounted() {
 
    this.$store.dispatch("updatePrice");
    if( this.$route.query.grocery == true  || this.$route.query.grocery == "true"){
      this.grocery = false;
      await this.$store.dispatch("getOrderDetails", this.$route.query);
    }
    else if (this.$route.query.h == true || this.$route.query.h == "true") {
      this.hopedOrder =  true ;
      await this.$store.dispatch("getOrderDetails", this.$route.query);
    } else {
      await this.$store.dispatch("getOrderDetails", this.$route.query);
    }

    this.id = this.$route.query.id;
    if (this.$route.query.h == true || this.$route.query.h == "true") {
      this.hopped = true;
      this.hopedOrder = true;
      console.log(this.hopped);
      let filterData = this.$store.state.details.order_items.filter((e) => {
        if (e.order_status == "pending") return e;
      });
      this.$store.state.details.order_items = filterData;
      this.data = this.$store.state.details;
      this.data.order_items.forEach((element) => {
        element.selected = 'pending';
      });
      } else {
        console.log("his.$store.state.details",this.$store.state.details);
        this.data = this.$store.state.details;
        console.log("elseeeeeeeeeeeeeeee", this.data)

        this.data.order_items.forEach((element) => {
          element.selected = true ;

        });
        console.log("this.data.order_items=== ",this.data.order_items);
      }
  },
  methods: {
    showPersonilization(msg){
      this.showMessage=true
      this.ordermessage=msg
    },
    changeproducts(i){
      var newelement=document.getElementById('add_'+i);
      if(newelement.classList.contains("checked")){
        newelement.classList.remove("checked");
        let id=this.checkitemselected[i].item_id;
        var itemindex;
        this.priceChangeProducts.forEach((ele,index)=>{
          if(ele.item_id==id){
           return itemindex=index
          }
        })
        this.priceChangeProducts.splice(itemindex,1)
        
      }

      else{
       newelement.classList.add("checked");
       this.priceChangeProducts.push(this.checkitemselected[i])
      }
      

    },
    async acceptupdatedorder(){
      this.hideclosepopbtn=false
      let token = window.$cookies.get("accessToken")
      let updateproductval=[]
      this.priceChangeProducts.forEach((ele)=>{
        updateproductval.push({'item_id':ele.item_id,
        'selling_price':parseInt(ele.selling_price)})
      });
      if(updateproductval.length==0){
         this.hideclosepopbtn=true
        return this.pricechangeError ="You Have not Checked any product"
       
      }
      
      let payload={
        'items':updateproductval,
        'order_quote_id':this.data.order_items[0].order_quote_id,
        'store_id':this.data.order_items[0].store_id,
        acceptedids:[],
        rejectedids:[]
       }
    
    this.data.order_items.forEach((e) => {
        if (e.selected) {
          e.status = "accepted";
        } else {
          e.status = "rejected";
        }
      });

      this.data.order_items.map((e) => {
        if (e.status == "accepted") {
          payload.acceptedids.push(e.item_id);
        }
        if (e.status == "rejected") {
          payload.rejectedids.push(e.item_id);
        }
      });
   
     try {
        const response = await axios.post('/vendor/update-pricing', payload,{
                    headers: {
                    'Authorization': `Bearer ${token}`
                    }
                    })
        console.log(response)
        this.acceptData();
     } catch (error) {
       this.pricechangeError =error.message
       this.hideclosepopbtn=true
     }
     
    },
    changePrice(){
      this.checkitemselected=this.data.order_items.filter(function myFunction(ele){
        return ele.selected==true
      })
      this.priceChange=true;
      
    },
    closeupdatepricemodal(){
      this.pricechangeError=''
    this.priceChange=false;
    this.checkitemselected=[]
    this.priceChangeProducts=[]
    },
    viewPrescription(item)
    {
      this.isPrescription = true;
      this.prescriptionData = item
    },
    closePrescription(){
      this.isPrescription = false;
      this.prescriptionData ="";
    },
    reloadPage(){
      console.log(this.action);
      if(this.action == "accepted"){
        window.location.href = "/orders/all?key=p";
      }
      if(this.action == "rejected"){
        window.location.href = "/orders/requests?key=";
      }
      if(this.action == "reload"){
        window.location.reload();
      }
      },
    acceptHoppedOrder(store_id){
      var params = {
        status: "accepted",
        order_quote_id: this.data.order_items[0].order_quote_id,
        si: store_id,
        ids: [],
      };
      this.data.order_items.map((e) => {
        params.ids.push(e.item_id);
      });
      let token = window.$cookies.get("accessToken")
      axios.post('/vendor/accept-reject-hopped-order',params,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response)=>{
        if(response){
          this.confirmDialog = true;
          this.isModal = false;
          this.action = "accepted";
        }
      })
    },
    rejectHoppedOrder(store_id){
      var params = {
        status: "rejected",
        order_quote_id: this.data.order_items[0].order_quote_id,
        si: store_id,
        ids: [],
      };
      this.data.order_items.map((e) => {
        params.ids.push(e.item_id);
      });
      let token = window.$cookies.get("accessToken")
      axios.post('/vendor/accept-reject-hopped-order',params,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response)=>{
        if(response){
          if(this.responseData.length > 1){
            this.confirmDialog = true;
            this.isModal = false;
            this.action = "reload";
          } else{
            this.confirmDialog = true;
            this.isModal = false;
            this.action = "rejected";

          }
        }
      })
    },
    openModal(){
      this.isModal = true;
      axios.get(`/vendor/get-hopped-stores/${this.data.order_items[0].order_quote_id}`,{
        headers: {
          'Authorization': `Bearer ${window.$cookies.get("accessToken")}`
        }
      })
      .then((response)=>{
        this.responseData = response;
      })
    },
    closeModal() {
      this.isModal = false;
    },
    numberFormate(data){
      return new Intl.NumberFormat("en-IN").format(data)
    },
    customChecked(action,itemId){
        if(action=="accept"){
          this.check=true
          document.getElementById('a_'+itemId).className='customCheckbox checked';
          document.getElementById('r_'+itemId).className='customUnCheckbox';
          this.data.order_items[itemId].selected = true;

        }else if(action=="reject"){
          document.getElementById('r_'+itemId).className='customUnCheckbox unchecked';
          document.getElementById('a_'+itemId).className='customCheckbox';
          this.data.order_items[itemId].selected = false;
          let checkitemselected=this.data.order_items.filter(function checkbtn(product) {
            return product.selected==true
          })
          if(checkitemselected.length==0){
              this.check=false
          }
        }

},
    accept(item,index){
      this.ind = index
      this.data.order_items[index].selected = true;
    },
    reject(item,index){
        this.ind = index
        this.data.order_items[index].selected = false;
    },
    async acceptOrderAll(status){
      // console.log("hoped", this.hopped, this.grocery);
      var params = {};
      console.log(this.isVendor);
      if(this.isVendor == true) {
        params = {
          status: status,
          order_quote_id: this.data.order_items[0].order_quote_id,
          ids: [],
          si: this.data.order_items[0].store_id
        };
      } else{
        params = {
          status: status,
          order_quote_id: this.data.order_items[0].order_quote_id,
          ids: [],
        };
      }

      this.data.order_items.map((e) => {
          params.ids.push(e.item_id);
      });
      let token = window.$cookies.get("accessToken")
      
      if(this.grocery == true && this.hopped === false){
        this.isConfirmLoader = true
        await axios.post('/vendor/accept-reject-order',params,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
              // console.log(res)
               this.isConfirmLoader = false

          console.log("res.data.total",res.data)
          this.$Msg.success("Action performed successfully", {
            position: "top-center",
            timeout: 2000,
          });
          this.$router.push("/orders/requests");

      })
      .catch((error) => {
        this.isConfirmLoader  = false
          console.error(error)
          this.$Msg.error(error, {
            position: "top-center",
            timeout: 2000,
          });
      })
      }
      else{
         await axios.post(`/vendor/accept-reject-hopped-order`,params,{
          headers: {
          'Authorization': `Bearer ${token}`
          }
      })
      .then((res) => {
          console.log("res.data.total",res.data)
          this.$Msg.success("Action performed successfully", {
            position: "top-center",
            timeout: 2000,
          });
          this.$router.push("/orders/requests");

      })
      .catch((error) => {
          console.error(error)
          this.$Msg.error(error, {
            position: "top-center",
            timeout: 2000,
          });
      })
      }
    },


    async acceptData() {
      this.updatepricebtndisbale=true;
      this.unselected = false;
      this.hopped = "";
      if (this.$route.query.h == true || this.$route.query.h == "true") {
        this.hopped = true;
      } else {
        this.hopped = false;
      }

     this.data.order_items.map((e) => {
          if(e.selected == 'pending') {
            console.log("enter");
            return this.unselected = true;
        }
      });
      if(this.unselected ){
        this.$Msg.error("Either accept or reject order", {
            position: "top-center",
            timeout: 3000,
        });
        return
      }
      this.data.order_items.forEach((e) => {
        if (e.selected) {
          e.status = "accepted";
        } else {
          e.status = "rejected";
        }
      });
//old logic 
      // var paramsAccepted = {};
      // var paramsReject = {};
// new logic
      var orderstatusparam={};
//old logic 
      // if(this.isVendor){
      //   paramsAccepted = {
      //     status: "accepted",
      //     is_hopped: this.hopped,
      //     order_quote_id: this.data.order_items[0].order_quote_id,
      //     ids: [],
      //     si: this.data.order_items[0].store_id,
      //   };
      //   paramsReject = {
      //     status: "rejected",
      //     is_hopped: this.hopped,
      //     order_quote_id: this.data.order_items[0].order_quote_id,
      //     ids: [],
      //     si: this.data.order_items[0].store_id,
      //   };
      // }else{
      //   paramsAccepted = {
      //     status: "accepted",
      //     is_hopped: this.hopped,
      //     order_quote_id: this.data.order_items[0].order_quote_id,
      //     ids: [],
      //   };
      //   paramsReject = {
      //     status: "rejected",
      //     is_hopped: this.hopped,
      //     order_quote_id: this.data.order_items[0].order_quote_id,
      //     ids: [],
      //   };
      // }
      
//new logic
     if(this.isVendor){
       orderstatusparam = {
          is_hopped: this.hopped,
          order_quote_id: this.data.order_items[0].order_quote_id,
          rejectedids: [],
          acceptedids:[],
          si: this.data.order_items[0].store_id,
        }
     }
     else{
        orderstatusparam = {
          is_hopped: this.hopped,
          order_quote_id: this.data.order_items[0].order_quote_id,
          rejectedids: [],
          acceptedids:[],
        };
     }
//old logic
      // this.data.order_items.map((e) => {
      //   if (e.status == "accepted") {
      //     paramsAccepted.ids.push(e.item_id);
      //   }
      //   if (e.status == "rejected") {
      //     paramsReject.ids.push(e.item_id);
      //   }
      // });

  //new logic 
   this.data.order_items.map((e) => {
        if (e.status == "accepted") {
          orderstatusparam.acceptedids.push(e.item_id);
        }
        if (e.status == "rejected") {
          orderstatusparam.rejectedids.push(e.item_id);
        }
      });

      // if (paramsAccepted.ids.length > 0) {
      //   this.isConfirmLoader = true 
        // console.log("param",paramsAccepted);
        // let res = await this.$store.dispatch("acceptOrder", paramsAccepted);
        // if(paramsAccepted.is_hopped == true){
        //   console.log("true")
          
        // }
        // this.message = res.message;
        //  this.$router.push("/orders/requests");
      //   this.isConfirmLoader = false
      // }
//old logic
      // if(this.hopped){
      //    if(paramsAccepted.ids.length==0 ||paramsReject.ids.length == 0){
      //       this.all =  true;
      //     }
      //     else{
      //         this.all =  false;
      //     }
      //     if(this.all == false){
      //         paramsReject.partial= true;
      //     }
      // }
//new logic

 if(this.hopped){
         if( orderstatusparam.acceptedids.length==0 ||orderstatusparam.rejectedids.length == 0){
            this.all =  true;
          }
          else{
              this.all =  false;
          }
          if(this.all == false){
              orderstatusparam.partial= true;
          }
      }

     //old logic
      // if (paramsReject.ids.length > 0) {

      //   let res = await this.$store.dispatch("acceptOrder", paramsReject);
      //    if(paramsReject.is_hopped == true){
      //     console.log("true")
      //   }
      //   this.message = res.message;
      // }
//new logic for API call dispatch


        this.isConfirmLoader = true 
  
        let res = await this.$store.dispatch("acceptOrder", orderstatusparam);
        
        if(orderstatusparam.is_hopped == true){
          console.log("true")
          
        }
        this.message = res.message;
        //  this.$router.push("/orders/requests");
        this.isConfirmLoader = false
    
      if (this.message == "Success") {
        this.$router.push("/orders/requests");
        this.$Msg.success("Action performed successfully", {
          position: "top-center",
          timeout: 2000,
        });
      } else {
        this.$Msg.error(this.message, {
          position: "top-center",
          timeout: 8000,
        });
      }
      this.updatepricebtndisbale=false;
      this.pricechangeError=''
      this.priceChangeProducts=[]
      this.hideclosepopbtn=true
    },
  },
};

</script>

<style scoped>

.personilizebtn{
  cursor:pointer;
  color:#007bff;
  font-weight: bold;
}
.table thead th {
  border: 0;
}
.container-fluid.align-items-left {
  padding-left: 13px !important;
}
.customCheckbox{
  width: 20px;
  height: 20px;
  display: inline-block;
  border:1px solid #555;
  border-radius: 100%;
  margin-left: 15px;;
}
.customCheckbox::before {
    margin:4px;
    content: "\2713";
    color:#555; 
} 
.customUnCheckbox{
  width: 20px;
  height: 20px;
  display: inline-block;
  border:1px solid #555;
  border-radius: 100%;
  margin-left: 15px;;
}
.customUnCheckbox::before {
      margin:4px;
      content: "\2715";
      color:#555 !important; 
      font-size: 13px;
} 
/* .customCheckbox.checked{
  content: '&#10003';
  background-color: blue ;
} */


.checked {
  width: 20px;
  height: 20px;
  display: inline-block;
  border:1px solid #555;
  border-radius: 100%;
  margin-left: 15px;;
  background-color:#2dce89;
  border-color:#2dce89;
}
.checked::before {
    margin:4px;
    content: "\2713";
    color:#fff; 
} 
.unchecked::before {
    margin:4px;
    content: "\2715";
    color:#fff !important; 
    font-size: 13px;
  
} 
.unchecked {
  background-color:red;
  font-size: 13px;
  border-color:red;
  color: red;
  
}
.newmodal{
    background-color: rgba(0, 0, 0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}
.newmodal-dialog{
    width:80%;
    position: relative;
    top: 6%;
    margin:0 auto;
    z-index: 3;
}
.pricechangeerr{
  margin-left:5%;
}
.modal-title{
  margin-left: 2%;
}
.prescrip {
  background-color: #5e72e4;
  margin-bottom: 8px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
</style>
    

