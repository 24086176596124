<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent': ''">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                {{title}} 
              </h3>
            </div>
            <div class="col text-right">
              <base-button type="primary" size="sm">See all</base-button>
            </div>
          </div>
        </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data"
                  :columnData="columns">
                  <template slot="columns" ></template>
      </base-table>
    </div>
  
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination total=120>{{data.total}}</base-pagination>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String,
      columns:Array,
      headers:[],
      data:[],
    },
    data() {
      return {
        tableData: [
        ],
        selected: [],
        selectAll: false
      }
    },
    methods: {
      select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.items) {
            this.selected.push(this.items[i].id);
          }
        }
      }
    },
    beforeMount(){
      console.log("header1",this.header)
      this.headers =  this.header

    },
    async mounted(){
      console.log("header2",this.data)
      this.headers =  this.header
      await this.$store.dispatch('getOrders', {item: '', key:''})
      
    },
  }
</script>
<style>
</style>
