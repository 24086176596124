<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <div class="container-fluid mt--7">
        
      <div>
        <div class="col-xl-8 ml-5" style="width: 100%">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="align-items-center">
                <div class="col-6">
                  <h2 class="mb-0">Change Password</h2>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <div class=" border-0" style="background-color:#f7fafc">
              <div class="password">
                    <span >Old Password</span>

                  <base-input class="input-group-alternative mb-4" type="password" v-model="oldPassword" placeholder="Old Password"
                    ></base-input>
                    <span v-if ="oldPasswordError" class="error">{{oldPasswordError}}</span>

                    <span >New Password</span>

                    <base-input class="input-group-alternative mb-4 mr-0"
                        placeholder="New Password" v-model="newPassword" 
                        type="password"
                    >
                    </base-input>
                    <span>Confirm Password</span>
                     <base-input class="input-group-alternative mb-4"
                                placeholder="Confirm Password" v-model="confirmPassword" 
                                type="password"
                            >
                    </base-input>
                    <span v-if="confirmError" class="error">{{confirmError}}</span>

              </div>
            </div>
            <div>
              <base-button
                class="mb-2 mr-4 mt-2"
                type="primary"
                size="sm"
                style="float: right"
                @click="update()"
                >Update</base-button
              >
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../axiosCustom"
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword:'',
      confirmError:'',
      oldPasswordError:'',
    };
  },
  methods:{
      async update(){
          if(this.newPassword != this.confirmPassword){
              this.confirmError = 'Password not match'
              this.oldPasswordError=''
              return
          }
          else{
              this.confirmError = ''
          }
          if(this.newPassword === this.oldPassword){
              console.log("in  ");
              this.oldPasswordError = 'Similar password'
              return
          }
           let token = window.$cookies.get("accessToken")
           let params={
               old_password: this.oldPassword,
               password_confirmation: this.confirmPassword,
               password:this.newPassword
           }
           const response = await axios.post(`/vendor/update-password`,params,{
            headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            this.$router.push('/dashboard')
            console.log("res",response)

      }
  },
  mounted(){

  }
};
</script>

<style>
.password {
  display: block;
  padding: 3%;
}
.password span {

  margin-right: 30px;
}


textarea:focus, input:focus{
    outline: none;
}
.error{
    color:red;
    font-size: 14px;
}

</style>
