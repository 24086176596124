<template>
    <div>
        <Modal :show="success_page" @close="reset_page">
            <div slot="header">
                <b>Success</b>
            </div>
            <div>{{success_message}}</div>
            <div slot="footer">
                <button 
                    type="button"
                    class="btn btn-success"
                    @click="reset_page"
                >
                    Ok
                </button>
            </div>
        </Modal>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
        <div class="container-fluid mt--7">
            <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                <div class="card-header"
                    :class="type === 'dark' ? 'bg-transparent': ''">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                {{ already_closed.is_verified ? `You are ${already_closed.close_type}` : "Store Closure Form"}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4" :class="type === 'dark' ? 'bg-transparent': ''" v-if="already_closed.is_verified === 'yes'">
                    <div class="row">
                        <div class="col-md-6" v-show="already_closed.from_close">
                            <div class="form-group">
                                <label for="fromDate">From Date</label>
                                <input class="form-control" type="date" id="fromDate" v-model="already_closed.from_close" disabled>
                            </div>
                        </div>
                        <div class="col-md-6" v-show="already_closed.to_close">
                            <div class="form-group">
                                <label for="toDate">To Date</label>
                                <input class="form-control" type="date" id="toDate" v-model="already_closed.to_close" disabled>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="reason">Reason</label>
                                <input class="form-control" type="text" id="reason" v-model="already_closed.reason" disabled>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4" :class="type === 'dark' ? 'bg-transparent': ''" v-else>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="storeName">Store Name</label>
                                <input class="form-control" type="text" id="storeName" v-model="store_name" disabled>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="storeId">Store Id</label>
                                <input class="form-control" type="text" id="storeId" v-model="store_id" disabled>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="selectedOption">Select Option</label>
                                <span class="ml-2" style="color: red">{{status_error}}</span>
                                <select class="form-control" id="selectedOption" v-model="status">
                                    <option v-for="(value, index) in options" :key="index" :value="value.value">{{ value.label }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12" v-show="status != 'permanent close'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="fromDate">From Date</label>
                                        <span class="ml-2" style="color: red">{{from_date_error}}</span>
                                        <input class="form-control" type="date" id="fromDate" v-model="from_date">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="toDate">To Date</label>
                                        <span class="ml-2" style="color: red">{{to_date_error}}</span>
                                        <input class="form-control" type="date" id="toDate" v-model="to_date">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="reason">Reason</label>
                                <span class="ml-2" style="color: red">{{reason_error}}</span>
                                <input class="form-control" type="text" id="reason" v-model="reason" placeholder="Reason to Close Store">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-primary" @click="store_closure()">Submit</button>
                            <span class="ml-2" style="color: red">{{page_error}}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="card-footer d-flex justify-content-end"
                    :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                </div>
                <div class="product_loader" v-show="loader == true"><img src="/img/loader.gif"></div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom"
import Modal from '../components/Modal.vue';

export default {
    name: 'Store Closure',
    components: {
        Modal
    },
    data () {
        return {
            loader: true,
            type: "",
            success_page: false,
            success_message: "",
            options: [
                {label: "Select Operation", value: 'Select Operation'},
                {label: "Temporary Close", value: 'close'},
                {label: "Permanent Close", value: 'permanent close'}
            ],
            status: "Select Operation",
            store_name: "",
            store_id: "",
            from_date: null,
            to_date: null,
            reason: "",
            page_error: "",
            status_error: "",
            from_date_error: "",
            to_date_error: "",
            reason_error: "",
            already_closed: {
                is_verified: false
            }
        }
    },
    mounted(){
      if(localStorage.getItem("role") == "vendor"){
        window.location.href = "/dashboard";
      } else{
        this.store_name = this.$store.state.user.name
        this.store_id = this.$store.state.user.store_id
      }
    },
    watch: {
        '$store.state.user'(){
            this.store_name = this.$store.state.user.name
            this.store_id = this.$store.state.user.store_id
        },
        store_id(){
            if (this.store_id) {
                this.get_store_status();
            }
        },
        status(){
            this.reset_errors()
        },
        from_date(){
            this.reset_errors()
        },
        to_date(){
            this.reset_errors()
        },
        reason(){
            this.reset_errors()
        }
    },
    methods: {
        async store_closure(){
            this.loader = true;
            if (this.status != 'Select Operation' && this.store_name && this.store_id && this.reason) {
                let formData = new FormData();
                if (this.status != 'permanent close') {
                    if (this.from_date && this.to_date) {
                        formData.append('from_date', this.from_date);
                        formData.append('to_date', this.to_date);
                    } else {
                        if (this.from_date == null){
                            this.from_date_error = "*It is a required field*";
                        }
                        if (this.to_date == null){
                            this.to_date_error = "*It is a required field*";
                        }
                        return;
                    }
                }
                formData.append('store_name', this.store_name);
                formData.append('store_id', this.store_id);
                formData.append('status', this.status);
                formData.append('start', this.store_name);
                formData.append('reason', this.reason);
                let token = window.$cookies.get("accessToken")
                const res = await axios.post("vendor/store-closure-request",
                    formData,
                    {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                if(res.status == true){
                    this.success_page = true
                    this.success_message = res.message
                }
            } else {
                if (this.store_name == "" || this.store_id == ""){
                    this.page_error = "*Please Reload the page and try again!*"
                }
                if (this.status == "Select Operation"){
                    this.status_error = "*It is a required field*"
                }
                if (this.from_date == null){
                    this.from_date_error = "*It is a required field*";
                }
                if (this.to_date == null){
                    this.to_date_error = "*It is a required field*";
                }
                if (this.reason == ""){
                    this.reason_error = "*It is a required field*"
                }
            }
            this.loader = false;
        },
        async get_store_status(){
            this.loader = true;
            let token = window.$cookies.get("accessToken")
            const res = await axios.get(`vendor/store-closure-status?store_id=${this.store_id}`,
                {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (res.status) {
                this.already_closed = res.data
            }
            this.loader = false;
        },
        reset_errors(){
            this.status_error = ""
            this.from_date_error = ""
            this.to_date_error = ""
            this.reason_error = ""
        },
        reset_page(){
            this.status = "Select Operation"
            this.from_date = null
            this.to_date = null
            this.reason = ""
            this.success_page = false
        },
    }
};
</script>