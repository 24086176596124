<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                       Banners
                                    </h3>
                                    </div>
                                    <div class="col text-right">
                                    <base-button type="primary" @click="add" size="sm">Upload Banner</base-button>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>
                                    <tbody :class="tbodyClasses">
                                        <tr v-for="item in data" :key="item.id">
                                        <td>
                                            <label class="form-checkbox">
                                                <input type="checkbox" :value="item.id_product" v-model="selected">
                                                <i class="form-icon"></i>
                                            </label>
                                        </td>
                                        <td>{{item.order_quote_id}}</td>
                                        <td v-if="item.order_items.length > 0">{{item.order_items[0].created_at}}</td>

                                        <td v-if="item.customer !== null">{{item.customer.full_name}}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{item.order_items.length}}</td>
                                        <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'tables',
    components: {
      
    },
    data () {
        return {
            data:[],
            selected: [],
            selectAll: false,
            headers : [
             { name: 'File',value:'discount'},
             { name: 'URL',value:'discount'},
             { name:'Size',value:'store'},
          
            ]
        }
    },
      props: {
   
        theadClasses: {
        type: String,
        default: '',
        description: '<thead> css classes'
        },
        tbodyClasses: {
        type: String,
        default: '',
        description: '<tbody> css classes'
        }
    },
    computed: {
        tableClass() {
        return this.type && `table-${this.type}`;
        },
    },
    
    async mounted(){
        await this.$store.dispatch('getOrders',  {item: this.page, key:'all'})
        // this.data= this.$store.state.orders
    },
    
    methods: {
        select() {
            this.selected = [];
                if (!this.selectAll) {
                    for (let i in this.data) {
                    this.selected.push(this.data[i].id_product);
                }
            }
        },
        add(){
            // this.$router.push('/add-banner')
        },
    }

  };
</script>
<style></style>
