<template>
 <div class="invoice-box mt-5">
   <table style="border: 1px solid #000; margin: auto; width: 100%; font-family: -webkit-pictograph;">
        <tr>
            <td>
              <img src="https://wndw.in/img/lhm_logo.png" height="50" alt=""/>
              <br/>
              <p style="font-weight: bold; margin: 0px; padding: 5px; font-size: 14px">DIP Registration Number - {{DIP}}</p>

            </td>
            <td style="text-align: right; padding: 10px;">
                <h3>TAX INVOICE</h3>
            </td>
        </tr>
        <tr>
            <td style="text-align: left; padding: 10px;">
                <p style="font-size: 14px; font-weight: bold">Sold By: </p>
                <p style="font-size: 14px;"> {{store.company_name}}</p>
                <p style="font-size: 14px;">{{store.address_primary}},{{store.address_secondary}}</p>
                <p style="font-size: 14px;">{{store.city}},{{store.state}}</p>
                <!-- <p style="font-size: 14px;">PAN</p> -->
                <p style="font-size: 14px;">GST Registration No. {{store.vendor_details.vendor_gst_number}}</p>
            </td>
         
            <td style="text-align: right; padding: 10px;">
                <p style="font-size: 14px;">Invoice Date: {{data.invoiceDate}}</p>
              <p style="font-size: 14px;">Order Number.: {{data.quote.order_increment_id}}</p>
                <p style="font-size: 14px;">
                 {{address.street_address}}<br>
                 {{address.city}}, {{address.state}}-{{address.pin_code}}<br>
                Contact: +91 {{address.phone}}</p>
                <p style="font-size: 14px;">Invocie Number:{{data.invoice}}</p>
                <p style="font-size: 14px;">Reference Number:
      
                  <!-- {{data.quote.invoice}} -->
                  </p>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table style="width: 100%; border-top: 1px solid #000; margin: 30px 0;">
                    <tr>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">S.No.</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">PRODUCT NAME, DESCRIPTION</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">UNIT PRICE</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">QTY</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">TAXABLE AMOUNT</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">CGST</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">SGST</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; ">
                            <p style="padding: 5px; margin: 0; font-weight: bold;">TOTAL</p>
                        </td> 
                    </tr>
                    <tr v-for="(item,i) in items" :key="i">
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{i+1}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{item.name}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{unitPrice(item.row_total )}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{item.qty}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{unitPrice(item.row_total )}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{cgst(item)}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; border-right: 1px solid #000;">
                            <p style="padding: 5px; margin: 0;">{{cgst(item)}}</p>
                        </td>
                        <td style="border-bottom: 1px solid #000; ">
                            <p style="padding: 5px; margin: 0;">{{ (item.row_total * item.qty).toFixed(2)}}</p>
                        </td> 
                    </tr>
                    
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table style="width: 100%; border-top: 1px solid #000; border-bottom: 1px solid #000;">
                    <tr>
                        <td>
                            <p style="padding: 10; margin: 0; font-weight: bold;">TOTAL AMOUNT:</p>
                        </td>
                        <td style=" text-align: right;">
                            <p style="padding: 10; margin: 0; font-weight: bold;">{{data.quote.grand_total.toFixed(2)}}</p>
                        </td>
                    </tr>
                </table>
                <table style="margin-bottom: 30px;">
                    <tr>
                        <td colspan="2">
                            <p style="margin: 0; padding: 0px; font-size: 14px;">All value are in INR</p>
                        </td>
                    </tr>
                </table>
            </td> 
        </tr>
        <tr>
            <td colspan="2">
                <table style="width: 100%; border-top: 1px solid #000; border-bottom: 1px solid #000; margin-bottom: 30px;">
                    <tr>
                        <td>
                            <p style="padding: 10; margin: 0; font-weight: bold;">Total amount in words: </p>
                        </td>
                        <td style=" text-align: right;">
                            <p style="padding: 10; margin: 0; font-weight: bold;">{{data.grand_total}} </p>
                        </td>
                    </tr>
                </table>
            </td> 
        </tr> 
        <tr>
            <td colspan="2" style="border-top: 1px solid #000; border-bottom: 1px solid #000;">
                <p style="margin: 0; text-align: right; padding-top: 30px;"><b>{{store.company_name}} <br>  Authorized Signature</b></p>
            </td>
        </tr>
        <tr v-if="data.quote.shipping_charge!=null && data.quote.shipping_charge!='' && data.quote.shipping_charge!=0">
            <td colspan="2">
                <p style="margin: 0; text-align: left; margin-top: 30px; border-top: 1px solid #000; padding: 5px;">
                    <span style="font-weight: bold;">Note:-</span> Delivery chargers of Rs.{{data.quote.shipping_charge}} paid to WNDW (Sakarashop Online Pvt. Ltd) 
                </p>
            </td>
        </tr>
        <tr v-if="windowPrintCall == false">
        <td colspan="9">
          <base-button type="primary" size="sm" style="float: right" @click="print">
            Print
          </base-button>
        </td>
      </tr>
    </table>


  
</div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
import axios from "../axiosCustom" ;
export default {
  components: { BaseButton },
  data (){
    return{
      DIP: "",
      store:{},
      address:{},
      windowPrintCall:false,
        items: [
        { description: "A", quantity: 1, price: 300 },
        { description: "B", quantity: 1, price: 75 },
        { description: "C", quantity: 1, price: 10 },
        ]
    }
  },
  computed: {
    total() {
      return this.items.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
    }
  },
  methods: {
    unitPrice(total){
      let taxPercent = 18;
      let UnitPrice = (total / ( ( taxPercent/100 ) +1))
      return UnitPrice.toFixed(2);
    },
    cgst(item){
      let taxPercent = 18;
      let UnitPrice = ((item.selling_price ) / ( ( taxPercent/100 ) +1)).toFixed(2)
      let data = ( UnitPrice * ( taxPercent / 100 ) ) /2
      return data.toFixed(2)
    },
    print() {
      this.windowPrintCall = true;
      window.onbeforeprint = () => {
        this.windowPrintCall = true;
      };
      setTimeout(function(){ window.print() }, 500);
    }
  },
  async mounted(){
      let d = new Date()
      this.date = `${d.getDate()}-${d.getMonth()}-${d.getFullYear()} `
      var token = window.$cookies.get("accessToken")
        let params = {
              quote_id:this.$route.query.id
      }
      var res = await axios.post('/vendor/get-invoice',params,{
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      this.address = res.address;
      this.store = res.store;
      this.items = res.products;
      this.data= res;
      this.DIP = res.dipp_registration_number;
  },
  filters: {
    currency(value) {
      return value.toFixed(2);
    }
  }

}
</script>

<style>
    
</style>
    