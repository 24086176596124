<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 order-xl-1 card-body-scoped">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h3 class="mb-0">Add Product Data</h3>
                                    <base-button type="primary" @click="importData()" size="sm" class="button">Import</base-button>
                                </div>

                            </div>
                        </div>
                       
                        <template>
                            <div >
                                <div class="row">
                                    <div class="col-xl-10    ml-7 mt-4 mb-4 ">
                                            <div class="form-group">
                                            <h4 class="font mb-3">Add Listing for Existing project </h4>
                                            <input type="text " v-model="id" class="form-control" placeholder="find existing product by LHMID">
                                            <base-button type="primary" @click="getProduct" size="sm" class="button">Search</base-button>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-10    ml-7  mb-4 ">
                                     <p   style="text-align:center;color: #999">-or-</p>
                                    </div> -->
                                    <!-- <div v-if="showTable == false" class="col-xl-10    ml-7  mb-4 text-center">
                                    <base-button type="primary" @click="add">Add New</base-button>
                                    </div> -->
                                </div>
                            </div>
                        </template>
                        <template v-if="showTable ">
                            <div class="table-responsive" >
                                <table class="table tablesorter">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th v-for="items in headers" :key="items.id"><b>{{items.name}}</b></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                        <td  class="pt-40">{{item.id_product}}</td>
                                        <td class="pl-0 pr-0" ><img :src="`https://images.wndw.in/live/inside/100x100/`+item.image" width="45px" height="auto">
                                            <a  class="ml-3 pointer" style="display:inline-block; " >{{item.name}}</a>
                                            <a class="margin pl-2 external-link-alt" style="display:inline-block" ></a>
                                        </td>
                                        <td class="pt-40">{{item.sku}}</td>
                                        <td><input type="text" v-model="item.price" @input="Quantity(item)" style="background-color:#f7fafc" class="input-class"></td>
                                            <td><input type="text" @input="Quantity(item)" v-model="item.selling_price" style="background-color:#f7fafc" class="input-class"></td>
                                            <td>
                                                <div class="qty_box"><a  @click='Quantity(item,index,0)'
                                                ><i class="fa fa-minus" ></i></a>
                                                <input type="text"  v-model ='item.quantity' @click="qtyChange(item, index)">
                                                <a   @click="Quantity(item,index,1)"
                                                ><i class="fa fa-plus"></i></a></div>
                                            </td>
                                        </tr>
                                        <!-- <td >{{calInventory(item)}}</td> -->
                                       <!-- <td v-if="item.categories.length>0">{{item.categories[0].name}}</td><td v-else></td> -->
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <div class="card-footer mb-0" v-if="showTable">
                              <div  class="col-xl-12  mb-0 text-right">
                                    <base-button type="primary" @click="addProduct">Confirm</base-button>
                                    </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <div v-if="importModel== true">
                <div class="modal import" style="display:block; width:100%">
                <div class="modal-dialog">
                    <div class="modal-content ml-279 t-160 import" style="width:390px; max-height:600px; ">
                        <div class="modal-header"><h3 class="modal-title" >Import file</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="importModel=false">
                        <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                            
                        <div class="modal-body import-body">
                            <div class="form-group">
                                <label for="files">Upload a CSV formatted file:</label>
                                <input type="file" id="files"  class="form-control" accept=".csv" required  @input="fileFormat($event)" />
                                </div>
                                <div class="form-group">
                                <button type="submit" id="submit-file" class="btn btn-primary" @click="importFile($event)">Upload File</button>
                            </div>
                            <!-- <vue-csv-import
                                v-model="csv"
                               >
                                <template slot="error" >
                                <p style="color:red">File type is invalid</p>
                                </template>
                               
                                </vue-csv-import>

                                 <vue-csv-submit >
                                    <base-button type="primary" style="float:right" @click="importFile(csv)" size="sm" >Import</base-button>
                                </vue-csv-submit> -->
                        </div>
                    </div>
                </div>
                </div>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom"
//  import { VueCsvImport } from 'vue-csv-import';
  export default {
    components: {  },
    name: '',
    csv:"",
    data() {
      return {
        activetab: 1 ,
       id:'',
       showTable:false,
       importModel:false,
       item:{},
        headers : [
            { name: 'ID',value:'discount'},
            { name: 'Product',value:'discount'},
            { name: 'Sku',value:'discount'},
            { name: 'Retail p',value:'discount'},
            { name: 'Selling p',value:'discount'},
            { name:'Inventory',value:'qty'},
        ]
      }
    },
     props: {
        theadClasses: {
            type: String,
            default: '',
            description: '<thead> css classes'
        },
        tbodyClasses: {
            type: String,
            default: '',
            description: '<tbody> css classes'
        },
        pageCount: {
            type: Number,
            default: 0,
            description:
            "Pagination page count. This should be specified in combination with perPage"
        },
        perPage: {
            type: Number,
            default: 10,
            description:
            "Pagination per page. Should be specified with total or pageCount"
        },
        total: {
        type: Number,
        default: 0,
        description:
            "Can be specified instead of pageCount. The page count in this case will be total/perPage"
        },
        value: {
        type: Number,
        default: 1,
        description: "Pagination value"
        },
        size: {
        type: String,
        default: "",
        description: "Pagination size"
        },
        align: {
        type: String,
        default: "",
        description: "Pagination alignment (e.g center|start|end)"
        }
    },
    mounted() {
      if(localStorage.getItem("role") == "vendor"){
        window.location.href = "/dashboard";
      }
    },
    methods:{
        add(){
            this.$router.push('/addnew-product')
        },
        async importData(){this.importModel = true},
        async fileFormat(e){
            this.file = e.target.files[0];
          
        },
        async importFile(){
            
          let token = window.$cookies.get("accessToken")
          var bodyFormData = new FormData();
          bodyFormData.append('csvFile' , this.file);

          this.url = '/vendor/bulk-lol'
          var that = this
    
            axios({
                method: 'post',
                url: this.url,
                data: bodyFormData,
                headers: {
                'Authorization': `Bearer ${token}`
            }
          })
            .then(function (response) {
                console.log(response);
                that.importModel = false;
                // location.reload()
            })
            .catch(function (response) {
                that.$Msg.error("Something went wrong", {
                    position: "top-center",
                    timeout: 3000,
                });
                that.importModel = false;
                // location.reload()
                // that.$router.push('/addnew')
                console.log(response);
            });
            // await axios.post(`/vendor/bulk-lolt/`,params,{
            //     headers: {
            //     'Authorization': `Bearer ${token}`
            //     }
            // })
            // .then((res)=>{
            //     console.log("this.item",res)
            //     })
            // .catch((e)=>{console.log("eee",e)
            //     this.showTable = false;
            //     this.item=''
            //     this.$Msg.error("Something went wrong", {
            //         position: "top-center",
            //         timeout: 3000,
            //     });
            // })
        },
        async Quantity(item,index,type){
            this.item =  item;
            if(type == 0){
              this.item.quantity= this.item.quantity -1 ;
            }
            if(type==1){
             this.item.quantity = this.item.quantity +1 ;
            }
            
        },
        async getProduct(){
            console.log("thi.os", this.id);
            let token = window.$cookies.get("accessToken")
            await axios.get(`/vendor/get-product/${this.id}`,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res)=>{
                this.showTable = true;
                this.item=  res.data[0] 
                console.log("this.item",this.item[0])

                })
            .catch((e)=>{console.log("eee",e)
                this.showTable = false;
                this.item=''
                this.$Msg.error("Something went wrong", {
                    position: "top-center",
                    timeout: 3000,
                });
            })
           

        },
        async addProduct(){
            console.log("this.item", this.item)
            let token = window.$cookies.get("accessToken");
            let params = {
                mp_id:this.item.source_product_id,
                price:this.item.price,
                selling_price:this.item.selling_price,
                quantity:this.item.quantity,
                sku: this.item.sku,
            };
            var res = await axios.post(`/vendor/save-product`,params,{
                headers: {
                  'Authorization': `Bearer ${token}`
                }
            })
            if(res.message=="Not Found"){
                console.log("error")
            }
            if(res.message == 'Product saved successfully'){
                this.$Msg.success("Product saved successfully", {
                    position: "top-center",
                    timeout: 3000,
                });
                this.$router.push('/products')
            }
            console.log("resgggg",res)
        },
    }
  };
</script>
<style scoped>
.search{
        position: relative;
        margin-top: 8px;
        width: 660px;
    font-size: 0.875rem;
    height: 150px;
 }                                 
.button{
    position: absolute;
    right: 33px;
    float: right;
    bottom: 30%;
}
.form-control {
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: .5em;
}
.pt-40{
    padding-top: 40px !important;
}
</style>
