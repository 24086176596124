<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                        Customer Pickups
                                        </h3>
                                    </div>
                                    <div class="col text-right">
                                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                                    </div>
                                </div>
                            </div>
                
                            <div class="product_loader" v-show="loader == true"><img src="/img/loader.gif"></div>

                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>

                                    <tbody :class="tbodyClasses">
                                        <tr v-for="item in data" :key="item.id" >
                                            <td><b
                                                >#{{ item.order_increment_id }}({{ item.order_quote_id }})</b
                                            ></td>
                                          <td v-if="isVendor && item.store_data != null && item.store_data.name != null">{{item.store_data.name}}</td>
                                          <td v-if="item.store_data == null || item.store_data.name == null"></td>

                                          <td v-if="item.created_at">{{ item.created_at }}</td>
                                            <td v-else></td>
                                            <td v-if="item.customer !== null">
                                            {{ item.customer.full_name }}
                                            </td><td v-else></td>
                                            <td v-if="item.grand_total">₹ {{ item.grand_total.toFixed(2) }}</td>
                                            <td v-else></td><td v-if="item.status">{{ item.status }}</td>
                                            <td v-else></td>
                                            <td v-if="item.accepted_orders_count">
                                            {{ item.accepted_orders_count }}
                                            </td>
                                            <td v-else></td>
                                            <td v-if="item.time_slot_date">{{ item.time_slot_date }}</td>
                                            <td v-else>To be Assigned</td>
                                            <td v-if="item.time_slot">{{ item.time_slot }}</td>
                                            <td v-else>To be Assigned</td>
                                            <td>
                                                <base-button type="primary" size="sm" @click="approve_item(item.order_quote_id, 1)" :disabled="approve === item.order_quote_id || reject === item.order_quote_id">Approve<i v-if="approve === item.order_quote_id" class="fa fa-spinner fa-spin"> </i></base-button>
                                                <base-button type="danger" size="sm" @click="approve_item(item.order_quote_id, 0)" :disabled="approve === item.order_quote_id || reject === item.order_quote_id">Reject<i v-if="reject === item.order_quote_id" class="fa fa-spinner fa-spin"> </i></base-button>
                                            </td>
                                        </tr>
                                        <tr v-if="data.length == 0"><td class="text-center" colspan="100%">  No Orders </td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div
                                class="card-footer d-flex justify-content-end"
                                :class="type === 'dark' ? 'bg-transparent' : ''"
                            >
                            </div>
                          </div>
                    <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom" 

export default {
    name: 'tables',
    components: {},
    data () {
        return {
            isVendor: false,
            loader:true,
            data:[],
            selected: [],page:1,
            selectAll: false,type:'',
            defaultPagesToDisplay: 3,
            headers : [
             { name: 'Date/Time' },
             { name: 'Customer' },
             { name: 'Total Amount' },
             { name: 'Payment Status' },
             { name: 'Items' },
             { name: 'Date Slot' },
             { name: 'Time Slot' },
             { name: 'Actions' }
            ],
            approve: null,
            reject: null
        }
    },
    props: {
        theadClasses: {
            type: String,
            default: '',
            description: '<thead> css classes'
        },
        tbodyClasses: {
            type: String,
            default: '',
            description: '<tbody> css classes'
        },
        pageCount: {
            type: Number,
            default: 0,
            description:
            "Pagination page count. This should be specified in combination with perPage"
        },
        perPage: {
        type: Number,
        default: 10,
        description:
            "Pagination per page. Should be specified with total or pageCount"
        },
        total: {
            type: Number,
            default: 0,
            description:
            "Can be specified instead of pageCount. The page count in this case will be total/perPage"
        },
        value: {
        type: Number,
        default: 1,
        description: "Pagination value"
        },
        size: {
        type: String,
        default: "",
        description: "Pagination size"
        },
        align: {
        type: String,
        default: "",
        description: "Pagination alignment (e.g center|start|end)"
        }
  },
  created() {
    if(localStorage.getItem("role") == "vendor"){
      this.isVendor = true;
      this.headers.unshift({ name: "Store" })
    }
    this.headers.unshift({ name: 'Order' },)
  },
  computed: {
        tableClass() {
         return this.type && `table-${this.type}`;
        },
        totalPages() {
            console.log("pageCount",this.pageCount)
            if (this.pageCount > 0) return this.pageCount;
            if (this.total > 0) {
                console.log("in total");
                        return Math.ceil(this.total / this.perPage);

            }
            return 1;
        },
        pagesToDisplay() {
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
            return this.totalPages;
        }
        return this.defaultPagesToDisplay;
        },
        minPage() {
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage > this.totalPages) {
            return this.totalPages - this.pagesToDisplay + 1;
            }
            return this.value - pagesToAdd;
        } else {
            return 1;
        }
        },
        maxPage() {
            console.log("max");
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage < this.totalPages) {
            return newMaxPage;
            } else {
            return this.totalPages;
            }
        } else {
            return this.pagesToDisplay;
        }
        }
    },
    watch: {
        perPage() {
        this.$emit("input", 1);
        },
        total() {
        this.$emit("input", 1);
        }
    },
    mounted(){
        this.fetchProducts();
    },    
    methods: {
        async fetchProducts(){
            this.loader = true;
            await this.$store.dispatch("getOrders", {item: this.page, key: this.$route.query.key});
            this.data = this.$store.state.orders;
            this.loader = false;
        },
        async approve_item(id, status){
            this.loader = true;
            if (status){
                this.approve = id
                this.reject = null
            }else{
                this.approve = null
                this.reject = id
            }
            let token = window.$cookies.get("accessToken")
            await axios.post(`/vendor/rto-action`,
                {
                    oid: id,
                    status: status
                },
                {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            this.fetchProducts();
            this.loader = false;
        }
    }
};
</script>
<style>
.table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}

.table td, .table th {
    text-align: center;
    
}
a.order{
    color:#2dce89;
    cursor: pointer;
}
</style>
