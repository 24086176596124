<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"> </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    {{pageType}} Orders 
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>

            <div class="product_loader" v-show="loader == true">
              <img src="/img/loader.gif" />
            </div>

            <div class="table-responsive">
              <table class="table tablesorter" :class="tableClass">
                <thead :class="theadClasses">
                  <tr>
                    <th v-for="item in headers" :key="item.id" class="header_font">
                      {{ item.name }}
                    </th>
                  </tr>
                </thead>

                <tbody :class="tbodyClasses">
                  <tr v-for="item in data" :key="item.id">
                    <!-- <td>
                                            <label class="form-checkbox">
                                                <input type="checkbox" :value="item.id_product" v-model="selected">
                                                <i class="form-icon"></i>
                                            </label>
                                        </td> -->
                    <td>
                      <a @click="details(item)" class="order"
                        ><b
                          >#{{ item.order_increment_id }}({{ item.order_quote_id }})</b
                        ></a
                      >
                    </td>
                    <td v-if="isVendor && item.store_data != null && item.store_data.name != null">{{item.store_data.name}}</td>
                    <td v-if="item.store_data == null || item.store_data.name == null"></td>

                    <td v-if="item.order_date">{{ item.order_date }}</td>
                    <td v-else></td>
                    <td v-if="item.customer !== null">
                      {{ item.customer.full_name }}
                    </td>
                    <td v-else></td>
                    <td v-if="item.grand_total">₹ {{ item.grand_total.toFixed(2) }}</td>
                    <td v-else></td>
                    <!-- <td v-if="item.payment_method">
                        {{ item.payment_method }}
                      </td>
                      <td v-else></td> -->
                    <td v-if="item.status">{{ item.status }}</td>
                    <td v-else></td>
                    <td v-if="item.accepted_orders_count">
                      {{ item.accepted_orders_count }}
                    </td>
                    <td v-else></td>
                    <td v-if="item.time_slot">{{ `${item.time_slot} , ${item.time_slot_date}` }}</td>
                    <td v-else></td>
                  </tr>
                  <tr v-if="data.length == 0"  > <td class="text-center" colspan="100%">  No Orders </td></tr>
                </tbody>
              </table>
            </div>

            <div
              class="card-footer d-flex justify-content-end"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <!-- <base-pagination total=170></base-pagination> -->
              <template v-if="data.length > 0">
                <ul
                  class="pagination"
                  :class="[
                    size && `pagination-${size}`,
                    align && `justify-content-${align}`,
                  ]"
                >
                  <li
                    class="page-item prev-page pointer"
                    :class="{ disabled: value === 1 }"
                  >
                    <a class="page-link" aria-label="Previous" @click="prevPage">
                      <span aria-hidden="true"
                        ><i class="fa fa-angle-left" aria-hidden="true"></i
                      ></span>
                    </a>
                  </li>
                  <li
                    class="page-item pointer"
                    :class="{ active: value === item }"
                    :key="item"
                    v-for="item in range(minPage, maxPage)"
                  >
                    <a class="page-link" @click="changePage(item)">{{ item }}</a>
                  </li>
                  <li
                    class="page-item next-page pointer"
                    :class="{ disabled: value === totalPages }"
                  >
                    <a class="page-link" aria-label="Next" @click="nextPage">
                      <span aria-hidden="true"
                        ><i class="fa fa-angle-right" aria-hidden="true"></i
                      ></span>
                    </a>
                  </li>
                </ul>
              </template>
            </div>
          </div>
          <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex'
export default {
  name: "tables",
  components: {},
  data() {
    return {
      isVendor: false,
      key: 'all',
      loader: true,
      // data: [],
      page: 1,
      selected: [],
      selectAll: false,
      type: "",
      defaultPagesToDisplay: 3,
      headers: [
        { name: "Date/Time", value: "discount" },
        { name: "Customer", value: "store" },
        { name: "Total Amount", value: "status" },
        // { name: "Payment Method", value: "status" },
        //  { name:'Delivery Method',value:'method'},
        { name: "Order Status", value: "status" },
        { name: "Items", value: "status" },
        { name: "Delivery Time", value: "time" },
      ],
    };
  },
  props: {
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    pageCount: {
      type: Number,
      default: 0,
      description:
        "Pagination page count. This should be specified in combination with perPage",
    },
    perPage: {
      type: Number,
      default: 10,
      page: 1,
      description: "Pagination per page. Should be specified with total or pageCount",
    },
    total: {
      type: Number,
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage",
    },
    value: {
      type: Number,
      default: 1,
      description: "Pagination value",
    },
    size: {
      type: String,
      default: "",
      description: "Pagination size",
    },
    align: {
      type: String,
      default: "",
      description: "Pagination alignment (e.g center|start|end)",
    },
  },

  computed: {
    pageType () {
      let pageType = 'All'
      let key = this.$route.query.key
      switch (key) {
        case 'c': {
          pageType = 'Completed'
          break;
        }
        case 'p': {
          pageType = 'Pending'
          break;
        }
        case 'f': {
          pageType = 'Failed'
          break;
        } 
      }
      return pageType

    },
    ...mapState({
      data:state => state.orders
    
      }),

    tableClass() {
      return this.type && `table-${this.type}`;
    },
    totalPages() {
      console.log("pageCount", this.pageCount);
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        console.log("in total");
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      console.log("max");
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
  },
  watch: {
     $route (newVal) {  
       if(newVal.query.key != 'all') {
          this.$store.dispatch("getOrders", {item: this.page, key:newVal.query.key});
       }
      
      },
    perPage() {
     
      this.$emit("input", 1);
    },
    total() {
      this.$emit("input", 1);
    },
  },
  created() {
    if(localStorage.getItem("role") == "vendor"){
      this.isVendor = true;
      this.headers.unshift({ name: "Store", value: "store" })
    }
    this.headers.unshift({ name: "Order", value: "discount" })
  },
  async mounted() {

    await this.$store.dispatch("getOrders", {item: this.page, key:this.$route.query.key});
    this.key = this.$route.query.key

    this.total = this.$store.state.totalOrder;
    console.log("this.totsl", this.total);
    console.log("this.", this.$store.state.orders);
    // this.data = this.$store.state.orders;
    this.loader = false;
  },
    

  methods: {
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.data) {
          this.selected.push(this.data[i].id_product);
        }
      }
    },
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    async changePage(item) {
      if (this.value != item) {
        this.loader = true;
        this.value = item;
        await this.$store.dispatch("getOrders", {item: item, key:this.$route.query.key});
        // this.data = this.$store.state.orders;
        this.loader = false;
        this.$emit("input", item);
      }
    },
    async nextPage() {
      console.log("thiss,value", this.value, this.totalPages);
      if (this.value < this.totalPages) {
        console.log("in enter");
        this.value = this.value + 1;
        await this.$store.dispatch("getOrders", {item: this.value, key:this.$route.query.key} );
        // this.data = this.$store.state.orders;
        this.loader = false;
        // await this.changePage(this.value)

        this.$emit("input", this.value + 1);
      }
      console.log("thiss,value", this.value, this.totalPages);
    },
    async prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
        this.value = this.value - 1;
        await this.$store.dispatch("getOrders", {item: this.value, key:this.$route.query.key} );
        // this.data = this.$store.state.orders;
        this.loader = false;
        // await this.changePage(this.value)
      }
    },
    async details(item) {
      console.log("teim", item);
      this.$router.push({
        name: "Details",
        query: { id: item.order_quote_id , key:this.pageType },
        params: { item: item },
      });
    },
  },
};
</script>
<style>
.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef;
}
.table td,
.table th {
  text-align: center;
}
a.order {
  color: #2dce89;
  cursor: pointer;
}
.header_font {
  font-size: 10px !important;
}
.card .table td,
.card .table th {
  padding-left: 12px !important;
}
</style>
