<template>
    <div>
        <Modal :show="success_page" @close="reset_page">
            <div slot="header">
                <b>Success</b>
            </div>
            <div>{{success_message}}</div>
            <div slot="footer">
                <button 
                    type="button"
                    class="btn btn-success"
                    @click="reset_page"
                >
                    Ok
                </button>
            </div>
        </Modal>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
        <div class="container-fluid mt--7">
            <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                <div class="card-header"
                    :class="type === 'dark' ? 'bg-transparent': ''">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                Inventory Update
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4" :class="type === 'dark' ? 'bg-transparent': ''">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="operationType">Operation Type</label>
                                <span class="ml-2" style="color: red">{{operation_type_error}}</span>
                                <select class="form-control" id="operationType" v-model="operation_type">
                                    <option v-for="(operation, index) in operationTypes" :key="index" :value="index">{{ operation }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="ScheduleDate">Schedule Date</label>
                                <span class="ml-2" style="color: red">{{selected_date_error}}</span>
                                <input class="form-control" type="date" id="ScheduleDate" v-model="selected_date">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div style="display: flex">
                                    <label for="csv">Upload CSV</label>
                                    <div class="ml-auto">
                                        Inventory: 
                                        <a :href="download_inventory">
                                            <button class="btn btn-outline-dark btn-sm">Click To Download</button>
                                        </a>
                                    </div>
                                </div>
                                <span style="color: red">{{file_error}}</span>
                                <input class="form-control" type="file" id="csv" ref="file">
                            </div>
                            <div class="form-group">
                                <label for="ScheduleTime">Schedule Time</label>
                                <span class="ml-2" style="color: red">{{selected_time_error}}</span>
                                <input class="form-control" type="time" id="ScheduleTime" v-model="selected_time">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="description">Description</label>
                                <span class="ml-2" style="color: red">{{description_error}}</span>
                                <input class="form-control" type="text" id="description" v-model="description" placeholder="Description">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-primary" @click="inventory_upload()">Upload</button>
                        </div>
                    </div>
                </div>
                <div
                    class="card-footer d-flex justify-content-end"
                    :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                </div>
                <div class="product_loader" v-show="loader == true"><img src="/img/loader.gif"></div>
            </div>
        </div>
        <div class="container-fluid mt-4">
            <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                <div class="card-header"
                    :class="type === 'dark' ? 'bg-transparent': ''">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                Inventory Report List
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table tablesorter" :class="tableClass">
                        <thead :class="theadClasses">
                            <tr>
                                <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                            </tr>
                        </thead>

                        <tbody :class="tbodyClasses">
                            <tr v-for="(item, index) in get_data" :key="index" >
                                <td>{{item.operation_type}}</td>
                                <td>{{item.schedule_date}}</td>
                                <td>{{item.counted_time}}</td>
                                <td>
                                    <a 
                                        :href="item.csv_file" 
                                        target="_blank" 
                                        v-if="item.status === 'Processed' || item.status === 'Failed'"
                                    >Download</a>
                                </td>
                                <td>
                                    <a 
                                        :href="item.csv_result" 
                                        target="_blank" 
                                        v-if="item.status === 'Processed' || item.status === 'Failed'"
                                    >Download Report</a>
                                </td>
                                <td>
                                    <div v-if="item.status === 'Failed'">
                                        <span class="customUnCheckbox unchecked" />
                                        <span class="ml-2">Failed</span>
                                    </div>
                                    <div v-else-if="item.status === 'Pending'">
                                        <span>Pending</span>
                                    </div>
                                    <div v-else-if="item.status === 'Processing'">
                                        <span>Processing</span>
                                    </div>
                                    <div v-else-if="item.status === 'Processed'">
                                        <span class="customCheckbox checked" />
                                        <span class="ml-2">Processed</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="data.length == 0"><td class="text-center" colspan="100%">  No Orders </td></tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="card-footer d-flex justify-content-end"
                    :class="type === 'dark' ? 'bg-transparent': ''"
                >
                        <!-- <base-pagination total=120 store='product' @clicked="onClickChild"></base-pagination> -->
                    <template v-if="data.length>0">
                        <ul class="pagination" :class="[size && `pagination-${size}`, align && `justify-content-${align}`]">
                            <li class="page-item prev-page pointer" :class="{disabled: page === 1}">
                                <a class="page-link" aria-label="Previous" @click="prevPage">
                                <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                </a>
                            </li>
                            <li class="page-item pointer" :class="{active: page === item}"
                                :key="item"
                                v-for="item in get_page_length">
                                <a class="page-link" @click="set_page(item)">{{item}}</a>
                            </li>
                            <li class="page-item next-page pointer" :class="{disabled: page === get_page_length}">
                                <a class="page-link" aria-label="Next" @click="nextPage">
                                    <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                </a>
                            </li>
                        </ul>
                    </template> 
                </div>
                <div class="product_loader" v-show="loader == true"><img src="/img/loader.gif"></div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom"
import Modal from '../components/Modal.vue';

export default {
    name: 'tables',
    components: {
        Modal
    },
    data () {
        return {
            loader: false,
            type: "",
            success_page: false,
            success_message: "",
            operation_type: 0,
            operationTypes: [
                "Select Operation",
                "Inventory Update",
            ],
            selected_date: null,
            selected_time: null,
            file: null,
            description: "",
            operation_type_error: "",
            selected_date_error: "",
            selected_time_error: "",
            file_error: "",
            description_error: "",
            headers : [
             { name: 'Operation Type' },
             { name: 'Date' },
             { name: 'Time' },
             { name: 'CSV File' },
             { name: 'CSV Result' },
             { name: 'Status' }
            ],
            data: [],
            page: 1
        }
    },
    watch: {
        operation_type(){
            this.reset_errors()
        },
        selected_date(){
            this.reset_errors()
        },
        selected_time(){
            this.reset_errors()
        },
        file(){
            this.reset_errors()
        },
        description(){
            this.reset_errors()
        }
    },
    methods: {
        async inventory_upload(){
            this.loader = true;
            const store_id = this.$store.state.user.store_id;
            const file = this.$refs.file.files[0];
            if (this.operation_type != 0 && this.selected_date != null && this.selected_time != null && file != undefined && this.description != "") {
                this.file = file
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('operation_type', this.operation_type);
                formData.append('start', this.selected_date);
                formData.append('store_id', store_id);
                formData.append('status', 0);
                formData.append('start_time', this.selected_time);
                formData.append('description', this.description);
                let token = window.$cookies.get("accessToken")
                const res = await axios.post("vendor/inventory-upload",
                    formData,
                    {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                if(res.success){
                    this.success_page = true
                    this.success_message = res.message
                }
            } else {
                if (this.operation_type == 0){
                    this.operation_type_error = "*It is a required field*"
                }
                if (this.selected_date == null){
                    this.selected_date_error = "*It is a required field*"
                }
                if (this.selected_time == null){
                    this.selected_time_error = "*It is a required field*"
                }
                if (this.file == undefined){
                    this.file_error = "*It is a required field*"
                }
                if (this.description == ""){
                    this.description_error = "*It is a required field*"
                }
            }
            this.loader = false;
            this.all_uploads();
        },
        reset_errors(){
            this.operation_type_error = ""
            this.selected_date_error = ""
            this.selected_time_error = ""
            this.file_error = ""
            this.description_error = ""
        },
        reset_page(){
            this.operation_type = 0
            this.selected_date = null
            this.selected_time = null
            this.file = null
            this.description = ""
            this.success_page = false
        },
        async all_uploads(){
            this.loader = true;
            let token = window.$cookies.get("accessToken")
            const res = await axios.post("vendor/get-inventory-result",
                {},
                {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            this.data = res.data;
            this.loader = false;
        },
        set_page (page) {
            this.page = page
        },
        prevPage(){
            this.page -= 1
        },
        nextPage(){
            this.page += 1
        }
    },
    mounted(){
      if(localStorage.getItem("role") == "vendor"){
        window.location.href = "/dashboard";
      }else {
        this.all_uploads();
      }
    },
    computed: {
        get_data () {
            return this.data.slice(10 * (this.page - 1), 10)
        },
        get_page_length (){
            return this.data.length ? Math.ceil(this.data.length / 10) : 1
        },
        download_inventory() {
            const store_id = this.$store.state.user.store_id;
            return `${axios.defaults.baseURL}/get-store-inventory?store=${store_id}`
        }
    }
};
</script>

<style scoped>
.customCheckbox{
    width: 20px;
    height: 20px;
    display: inline-block;
    border:1px solid #555;
    border-radius: 100%;
    text-align: center;
}
.customCheckbox::before {
    margin:4px;
    content: "\2713";
    color:#555; 
} 
.customUnCheckbox{
    width: 20px;
    height: 20px;
    display: inline-block;
    border:1px solid #555;
    border-radius: 100%;
    text-align: center;
}

.customUnCheckbox::before {
    margin:4px;
    content: "\2715";
    color:#555 !important; 
    font-size: 13px;
}
.checked {
    width: 20px;
    height: 20px;
    display: inline-block;
    border:1px solid #555;
    border-radius: 100%;
    background-color:#2dce89;
    border-color:#2dce89;
}
.checked::before {
    margin:4px;
    content: "\2713";
    color:#fff; 
}

.unchecked::before {
    margin:4px;
    content: "\2715";
    color:#fff !important; 
    font-size: 13px;
  
} 
.unchecked {
    background-color:red;
    font-size: 13px;
    border-color:red;
    color: red;
}
</style>
