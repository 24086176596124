<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <div shadow type="secondary">
                        <div slot="header" class="bg-white border-0 card-header-scoped p-3">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <div class="tabs">
                                        <a v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">Primary Info</a>
                                        <a v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">Other Attributes</a>
                                        <a v-on:click="activetab=3" v-bind:class="[ activetab === 3 ? 'active' : '' ]">Image</a>
                                        <a v-on:click="activetab=3" v-bind:class="[ activetab === 4 ? 'active' : '' ]">Seo</a>
                                        <a v-on:click="activetab=3" v-bind:class="[ activetab === 5 ? 'active' : '' ]">Configurable Products</a>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 order-xl-1 card-body-scoped">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Add Product Data</h3>
                                </div>
                            </div>
                        </div>
                        <div v-if="activetab === 1" class=" bg-white border-0">
                           <form @submit.prevent>
                                
                                <div class="row mb-3 pt-4">
                                    <div class="col-6 pl-lg-5">
                                        <label class="font" for="exampleFormControlSelect1">Select Parent</label>
                                        <select class="form-control" id="exampleFormControlSelect1">
                                            <option>Select Device</option>
                                            <option>Desktop</option>
                                            <option>Mobile</option>
                                        </select>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                    <div class="form-group">
                                        <lavel class="font"> Sku</lavel>
                                        <input type="text " class="form-control">
                                    </div>
                                </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Name</lavel>
                                            <input type="text " class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Visibility</lavel>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Device</option>
                                                <option>Desktop</option>
                                                <option>Mobile</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Price</lavel>
                                            <input type="text " class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Selling Price</lavel>
                                            <input type="text " class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group datetimepicker">
                                            <lavel class="font">Selling Price From Date</lavel>
                                            <datepicker :typeable="true" input-class="form-control" v-model="fromDate" name="uniquename"></datepicker>
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Selling Price To Date</lavel>
                                            <datepicker :typeable="true" input-class="form-control" v-model="fromDate" name="uniquename"></datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Tax Percentage</lavel>
                                            <input type="text " class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Stock Status</lavel>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Device</option>
                                                <option>Desktop</option>
                                                <option>Mobile</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Quantity</lavel>
                                            <input type="text " class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Status</lavel>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Device</option>
                                                <option>Desktop</option>
                                                <option>Mobile</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Category</lavel>
                                            <input type="text " class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-6 pr-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Store</lavel>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Select Device</option>
                                                <option>Desktop</option>
                                                <option>Mobile</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pl-lg-5">
                                        <div class="form-group">
                                            <lavel class="font">Description</lavel>
                                            <textarea row=5  class="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                 <div class="col text-left pl-4 ml-2 pb-4">
                                    <base-button type="primary" size="sm">Save</base-button>
                                    </div>
                               
                            </form>
                            </div>
                        <template>
                        <div >
                            
                            <div v-if="activetab === 2" class="tabcontent">
                            Content for tab two
                            </div>
                            <div v-if="activetab === 3" class="tabcontent">
                            Content for tab three
                            </div>
                        </div>
                            
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
  export default {
      components: { Datepicker },
    name: 'user-profile',
    data() {
      return {
        activetab: 1 ,
        model: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          zipCode: '',
          about: '',
        }
      }
    },
  };
</script>
<style scoped>
                                        
  .image-class{
      margin-top: 2.5rem !important;
  }
.tabs {
    overflow: hidden;margin-top: 2px;
    border-bottom: 1px solid rgb(117, 111, 111);
}

.tabs ul {
    border: none;
    list-style-type: none;
    margin-left: 20px;
}
.tabs a{
    float: left;
    cursor: pointer;
    padding: 12px 24px;
    /* transition: background-color 0.2s; */
    border: none;
   width:auto;
    /* background-color: #f1f1f1; */
    font-weight: bold;
}
.tabs a:last-child { 
    /* border-right: 1px solid #ccc; */
}

/* Change background color of tabs on hover */
.tabs a:hover {
    background-color: #aaa;
    color: #fff;
}

/* Styling for active tab */
.tabs a.active {
    background-color: #fff;
    color: #484848;
    cursor: default;
    border-bottom: 1px solid rgb(17, 16, 16);

}

/* Style the tab content */
.tabcontent {
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
  box-shadow: 3px 3px 6px #e1e1e1
}
.card-body-scoped {
    margin-top: 33px !important;
}
.card-header-scoped{
    padding-top: 15px;
    /* padding: 0 !important; */
}
.font{ font-weight: 800;
margin-bottom: 0;}
.vdp-datepicker.form-control {
    padding: 1;
    background-color: aquamarine;
}
.bg-color{background-color: white;}
.customcss div input{
    width: 100% !important;
    height: calc(1.5em + 1.25rem + 2px) !important;
    padding: 0.625rem 0.75rem !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #8898aa !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #cad1d7 !important; }


</style>
