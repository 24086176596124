<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"> </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Order Requests
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>

            <div class="product_loader" v-show="loader == true">
              <img src="/img/loader.gif" />
            </div>

            <div class="table-responsive">
              <table class="table tablesorter" :class="tableClass">
                <thead :class="theadClasses">
                  <tr>
                    <!-- <th>
                                            <label class="form-checkbox m-0">
                                                <input type="checkbox" v-model="selectAll" @click="select">
                                                <i class="form-icon"></i>
                                            </label>
                                        </th> -->
                    <th v-for="item in headers" :key="item.id">
                      <span>{{ item.name }}</span>
                    </th>
                  </tr>
                </thead>

                <tbody :class="tbodyClasses">
                  <tr v-for="item in data" :key="item.id">
                    <!-- <td>
                                            <label class="form-checkbox">
                                                <input type="checkbox" :value="item.id_product" v-model="selected">
                                                <i class="form-icon"></i>
                                            </label>
                                        </td> -->

                    <td>
                      <a @click="details(item)" class="order"
                        ><b
                          >#{{ item.order_increment_id }}({{ item.order_quote_id }})</b
                        ></a
                      >
                    </td>
                    <td v-if="isVendor && item.store_data != null && item.store_data.name != null">{{item.store_data.name}}</td>
                    <td v-if="item.store_data == null || item.store_data.name == null"></td>
                    <td v-if="item.created_at">{{ item.created_at }}</td>
                    <td v-else></td>
                    <!-- <td v-if="item.customer !== null">{{item.customer.full_name}}</td><td v-else></td> -->
                    <td>
                      ₹
                      {{
                        numberFormate(item.grand_total)
                      }}
                    </td>
                    <!-- <td v-if="item.payment_method">
                      {{ item.payment_method }}
                    </td>
                    <td v-else></td> -->
                    <td v-if="item.status">{{ item.status }}</td>
                    <td v-else></td>
                    <td>{{ item.order_items.length }}</td>
                    <!-- <td v-if="item.prescription" @click="viewPrescription(item.prescription)"><button>View</button></td>
                    <td v-else></td> -->
                    <!-- <td v-if="item.timing">{{item.timing.time_string}}</td><td v-else></td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="card-footer d-flex justify-content-end"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <!-- <base-pagination total=170></base-pagination> -->
              <!-- <template v-if="data.length>0">
                                <ul class="pagination" :class="[size && `pagination-${size}`, align && `justify-content-${align}`]">
                                    <li class="page-item prev-page" :class="{disabled: value === 1}">
                                        <a class="page-link" aria-label="Previous" @click="prevPage">
                                            <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{active: value === item}"
                                        :key="item"
                                        v-for="item in range(minPage, maxPage)">
                                    <a class="page-link" @click="changePage(item)">{{item}}</a>
                                    </li>
                                    <li class="page-item next-page" :class="{disabled: value === totalPages}">
                                    <a class="page-link" aria-label="Next" @click="nextPage">
                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </a>
                                    </li>
                                </ul>
                                </template> -->
            </div>
          </div>
          <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
        </div>
      </div>
    </div>
    <!-- <div v-if="isPrescription == true">
                      <div class="modal" style="display: block; width: 100%">
                        <div class="modal-dialog">
                          <div class="modal-content t-300" style="width: 790px">
                            <div class="modal-header">
                              <h3 class="modal-title">Print Slip</h3>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click="closePrescription"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <template>
                                <table class="table tablesorter" :class="tableClass">
                                  <thead :class="theadClasses">
                                    <tr v-for="item in images" :key="item.id">
                                      <th >
                                       Order ID:
                                      </th>
                                      <th>{{item.order_quote_id}}</th>
                                    </tr>
                                  </thead>
                                  <tbody :class="tbodyClasses">
                                    <tr v-for="(item , i) in images" :key="item.id">
                                      <td>{{ i + 1 }}</td>
                                      <td ><img class="prespic" :src="item.image_url" ></td>
                                      <td>{{ item.order_quote_id }}</td>  
                                      <td>
                                        <label class="form-checkbox">
                                          <input type="checkbox" :value="i" v-model="item.selectedItem" />
                                          <i class="form-icon"></i>
                                        </label>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </template>
                            </div>
                            <div class="modal-footer">
                              <base-button
                                type="primary"
                                size="sm"
                                style="float: right"
                                @click="printSlip(totalReadyItems)"
                                >Print</base-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
  </div>
</template>
<script>
//import { getMessaging, onMessage } from 'firebase/messaging';
// import { messaging } from '../firebase'

export default {
  name: "tables",
  components: {},
  data() {
    return {
      isPrescription: false,
      isVendor: false,
      loader: true,
      data: [],
      oder_quote_id : "",
      images :[],
      selectedItem: true,
      type: "",
      selected: [],
      selectAll: false,
      page: 1,
      defaultPagesToDisplay: 3,
      headers: [
        { name: "Date/Time", value: "discount" },
        { name: "Total Amount", value: "status" },
        // { name: "Payment Status", value: "status" },
        { name: "Order Status", value: "status" },
        { name: "Items", value: "status" },
        //  { name:'Prescribed_Items',value:"status"},
      ],
    };
  },
  props: {
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    pageCount: {
      type: Number,
      default: 0,
      description:
        "Pagination page count. This should be specified in combination with perPage",
    },
    perPage: {
      type: Number,
      default: 10,
      description: "Pagination per page. Should be specified with total or pageCount",
    },
    total: {
      type: Number,
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage",
    },
    value: {
      type: Number,
      default: 1,
      description: "Pagination value",
    },
    size: {
      type: String,
      default: "",
      description: "Pagination size",
    },
    align: {
      type: String,
      default: "",
      description: "Pagination alignment (e.g center|start|end)",
    },
  },

  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    totalPages() {
      console.log("pageCount", this.pageCount);
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      console.log("max");
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
  },
  watch: {

    perPage() {
      this.$emit("input", 1);
    },
    total() {
      this.$emit("input", 1);
    },
  },
  async mounted() {
    await this.$store.dispatch("getReqOrder");
    this.data = this.$store.state.reqOrders;
    this.loader = false;

    // if (messaging) {
    //   navigator.serviceWorker.addEventListener("message", async (message) => {
    //     //const payload = message.data.firebaseMessaging.payload
    //     console.log(message)
    //     alert('new order received bitch')
    //     await this.$store.dispatch("getReqOrder");
    //     this.data = this.$store.state.reqOrders;
    //     this.loader = false;
    //   });
    // }
    // else {
    //   alert('Notifications will not work on this browser please change your browser')
    // }
  },
  created(){
    if(localStorage.getItem("role") == "vendor"){
        this.isVendor = true;
        this.headers.unshift({ name: "Store", value: "store" })
    }  
        this.headers.unshift({ name: "Order", value: "discount" })

    },
  methods: {
    closePrescription(){
      this.isPrescription = false;
      this.images ="";
    },
    printSlip() {
      let ids = [];
      const basePath = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`:''}`
      this.totalReadyItems.forEach((e) => {
        if (e.selectedItem == true) {
          ids.push(e.order_quote_id);
        }
      });
      this.$cookies.set("ids", JSON.stringify(ids));
      let res = window.open(`${basePath}/slip?id=${this.totalReadyItems[0].order_quote_id}`, "_blank");
      //  let res = window.open(
      //   `http://localhost:8081/slip?id=${this.totalReadyItems[0].order_quote_id}`,
      //   "_blank"
      // );
      this.isPrescription = false;
      if (res == undefined) this.$cookies.remove("ids", JSON.stringify(ids));
    },
    viewPrescription(item)
    {
      this.images = item;
      this.isPrescription = true;
    },
     numberFormate(data){
      return new Intl.NumberFormat("en-IN").format(data)
    },
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    async changePage(item) {
      if (this.value != item) {
        this.loader = true;
        this.value = item;
        await this.$store.dispatch("getReturns", item);
        this.data = this.$store.state.returns;
        this.loader = false;
        this.$emit("input", item);
      }
    },
    async nextPage() {
      if (this.value < this.totalPages) {
        this.value = this.value + 1;
        await this.changePage(this.value);
        this.$emit("input", this.value + 1);
      }
    },
    async prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
        this.value = this.value - 1;
        await this.changePage(this.value);
      }
    },
    async details(item) {
      
      console.log("teim", item);
      if(item.order_items[0].is_grocery == 1 && item.is_hopped === true){
          let id = `${item.order_quote_id}`;
          this.$router.push({
            name: "Order Details",
            query: { id: id, grocery: true ,h: true,type:'pending_order'},
            params: { item: item },
          });
          return;
      }
      if(item.order_items[0].is_grocery == 1){
          let id = `${item.order_quote_id}`;
          this.$router.push({
            name: "Order Details",
            query: { id: id, grocery: true,type:'pending_order' },
            params: { item: item },
          });
          return;
      }
      if (item.is_hopped === true) {
        let id = `${item.order_quote_id}`;
        this.$router.push({
          name: "Order Details",
          query: { id: id, h: true,type:'pending_order' },
          params: { item: item },
        });
      } else {
        this.$router.push({
          name: "Order Details",
          query: { id: item.order_quote_id,type:'pending_order' },
          params: { item: item },
        });
      }
      return;
    },
  },
};
</script>
<style>
.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef;
}
.table td,
.table th {
  text-align: center;
}
a.order {
  color: #2dce89;
  cursor: pointer;
}
.prespic{
     height: 76px;
    width: 179px;
}
</style>
